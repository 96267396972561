import { ChangeDetectorRef, Component, type OnDestroy, type OnInit, ViewChild } from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  LoginService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { type TrackAndTraceVehicleDTOExtended, type VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu
  user: User
  loggedIn: boolean
  vehicles: VehicleDTOExtended[] = []
  inactiveVehicles: VehicleDTOExtended[] = []
  vehiclesTNT: TrackAndTraceVehicleDTOExtended[] = []
  firstVehicleVin: string
  hasMarketplace
  hasMyMailbox
  channels: boolean
  hasTestView
  navigationMarketplace
  sub = {
    onUserLoaded: null,
    combinedVehiclesSubscription: null,
    onResize: null
  }

  public screenSize: SCREEN_SIZE
  constructor (
    private readonly dataService: DataService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    public resizeService: ResizeService,
    private readonly loginService: LoginService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.screenSize = this.resizeService.screenSize
    this.hasMarketplace = this.remoteConfigService.get('hasMarketPlace')
    this.hasMyMailbox = this.remoteConfigService.get('hasMyMailbox')
    this.hasTestView = this.remoteConfigService.get('hasTestView')
    this.navigationMarketplace = this.remoteConfigService.getArrayValues<any>(
      'navigationMarketplace'
    )
  }

  get hasVehicles (): boolean {
    return this.vehicles?.length > 0 || this.inactiveVehicles?.length > 0 || this.vehiclesTNT?.length > 0
  }

  get firstVehicle (): VehicleDTOExtended | TrackAndTraceVehicleDTOExtended {
    return this.vehicles?.[0] || this.vehiclesTNT?.[0] || this.inactiveVehicles?.[0]
  }

  logout (): void {
    this.loginService.logout(this.user)
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.dataService.onUserConsentsLoaded.subscribe(consents => {
      this.channels = this.sharedService.hasConsentsForEFDS(consents)
    })

    this.sub.combinedVehiclesSubscription = this.dataService.combinedVehiclesLoaded
      .subscribe(([vehicles, inactiveVehicles, vehiclesTNT]) => {
        this.vehicles = vehicles
        this.inactiveVehicles = inactiveVehicles
        this.vehiclesTNT = vehiclesTNT?.filter(el => !(el.isHistory ?? false))
        if (this.firstVehicleVin == null && this.hasVehicles) {
          this.firstVehicleVin = 'commissionNumber' in this.firstVehicle
            ? `commissionNumber-${this.firstVehicle.commissionNumber}`
            : this.firstVehicle?.vin
        }
      }, err => {
        if (err.status === 404) {
          this.vehicles = []
          this.inactiveVehicles = []
        }
      })

    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
      this.cdRef.detectChanges()
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
