import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DataService } from '../../../../services/data.service'
import { type VehiclesResponse, type VehicleDTOExtended, VehiclesService, type TrackAndTraceVehicleDTOExtended } from '../../../../services/vehicles.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { MatDialog } from '@angular/material/dialog'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { AddNewVehicleComponent } from '../../../vehicle-list/add-new-vehicle/add-new-vehicle.component'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { type Observable } from 'rxjs'

@Component({
  selector: 'customer-portal-app-does-not-have',
  templateUrl: './does-not-have.component.html'
})
export class DoesNotHaveComponent implements OnInit, OnDestroy {
  vehicles: VehicleDTOExtended[] = []
  inactiveVehicles: VehicleDTOExtended[] = []
  vehiclesTNT: TrackAndTraceVehicleDTOExtended[] = []
  homepage
  sub = {
    combinedVehiclesSubscription: null,
    onUserLoaded: null
  }

  user: User
  constructor (
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly localStorageService: LocalStorageService,
    private readonly dialog: MatDialog,
    public sharedService: SharedService,
    private readonly vehiclesService: VehiclesService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
  }

  get tab (): string {
    return this.vehiclesService.getVehicleTab()
  }

  get hasVehicles (): boolean {
    return this.vehicles?.length > 0 || this.inactiveVehicles?.length > 0 || this.vehiclesTNT?.length > 0
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(value => {
      this.user = value
    })

    this.sub.combinedVehiclesSubscription = this.dataService.combinedVehiclesLoaded
      .subscribe(([vehicles, inactiveVehicles, vehiclesTNT]) => {
        this.vehicles = vehicles
        this.inactiveVehicles = inactiveVehicles
        this.vehiclesTNT = vehiclesTNT?.filter(el => !(el.isHistory ?? false))
        if (this.hasVehicles) {
          void this.router.navigate([this.homepage])
        }
      })
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  addNewVehicle (): void {
    const dialogRef = this.dialog.open(AddNewVehicleComponent, {
      data: this.user,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.error != null) {
          const data = {
            title: 'shared.error',
            text: 'customerPortal.customer-portal.add-new-vehicle.failure.text'
          }
          if (
            result.error?.message?.includes('has owner') === true ||
            result.error?.errorMessage?.includes('has owner') === true ||
            result.message?.includes('has owner') === true
          ) {
            data.text =
              'customerPortal.customer-portal.add-new-vehicle.failure.text.has-owner'
          }
          this.dialog.open(ConfirmationPopupComponent, {
            data,
            panelClass: 'mat-dialog-cpt'
          })
        } else {
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.success',
              text: 'customerPortal.customer-portal.add-new-vehicle.success.text'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.load().subscribe((data: VehiclesResponse) => {
            this.vehicles = data.userVehicles
            this.dataService.userVehiclesLoaded(data.userVehicles)
            void this.dataService.loadVehicleData(result)
            this.goToVehicle(result, this.tab)
          })
        }
      }
    })
  }

  goToVehicle (v: VehicleDTOExtended, tab: string): void {
    this.sharedService.goToVehicle(v, tab)
  }

  load (): Observable<VehiclesResponse> {
    return this.vehiclesService.getVehicles(true, false)
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
