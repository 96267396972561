import { Component, Inject, type OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { SharedService } from '../../../services/shared.service'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { DataService } from '../../../services/data.service'

import {
  ConfirmationPopupComponent,
  type ConfirmationPopupDialogData
} from '../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../basic/confirmation-question-popup/confirmation-question-popup.component'
import {
  type ReasonEnum,
  type VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'

interface DialogData {
  vehicle: VehicleDTOExtended
}

@Component({
  selector: 'customer-portal-app-remove-vehicle-popup',
  templateUrl: './remove-vehicle-popup.component.html'
})
export class RemoveVehiclePopupComponent implements OnInit {
  removalRequestReasons: ReasonEnum[] = ['EXCHANGE', 'NOT_OWNER', 'SOLD']

  selectedRemovalReason: ReasonEnum
  saving = false
  user: User
  hidden = false

  constructor (
    private readonly vehicleService: VehiclesService,
    private readonly dialog: MatDialog,
    private readonly matDialogRef: MatDialogRef<RemoveVehiclePopupComponent>,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  ngOnInit (): void {
    this.cptGtmService.sendOwnershipChangeData(1)
  }

  saveChanges (dialogRef?): void {
    this.saving = true
    const lang =
      this.user.language != null
        ? this.user.language.find(s => s.primary).language
        : 'de'
    this.vehicleService
      .requestVehicleRemoval(
        this.data?.vehicle?.vin,
        this.selectedRemovalReason,
        lang
      )
      .subscribe(
        () => {
          let title = 'shared.thank-you'
          let text =
            'customerPortal.customer-portal.vehicle.remove.success.text2'
          if (dialogRef != null) {
            dialogRef.close()
            title =
              'customerPortal.customer-portal.vehicle.remove.manually-added.success.title'
            text =
              'customerPortal.customer-portal.vehicle.remove.manually-added.success.text'
          }
          this.saving = false
          this.matDialogRef.close({ success: true, value: new Date() })
          const dataSuccess: ConfirmationPopupDialogData = {
            title,
            text,
            useInnerHtml: true
          }
          this.dialog.open(ConfirmationPopupComponent, {
            data: dataSuccess,
            panelClass: 'mat-dialog-cpt'
          })

          this.cptGtmService.sendOwnershipChangeData(
            2,
            this.data.vehicle,
            this.selectedRemovalReason
          )
        },
        () => {
          const title =
            'customerPortal.customer-portal.vehicle.remove.failure.title'
          let text =
            'customerPortal.customer-portal.vehicle.remove.failure.text'
          if (dialogRef != null) {
            dialogRef.close()
            text =
              'customerPortal.customer-portal.vehicle.remove.manually-added.failure.text'
          }
          this.saving = false
          this.matDialogRef.close({ success: false })
          const dataFailure: ConfirmationPopupDialogData = {
            title,
            text,
            useInnerHtml: true
          }
          this.dialog.open(ConfirmationPopupComponent, {
            data: dataFailure,
            panelClass: 'mat-dialog-cpt'
          })
          this.cptGtmService.sendOwnershipChangeData(
            2,
            this.data.vehicle,
            this.selectedRemovalReason
          )
        }
      )
  }

  confirmDeleteVehicle (): void {
    this.hidden = true
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.vehicle.remove.confirm.title',
        text: 'customerPortal.customer-portal.vehicle.remove.confirm.text',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.saveChanges(dialogRef)
    })
    dialogRef.afterClosed().subscribe(() => {
      this.hidden = false
      sub.unsubscribe()
    })
  }
}
