import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  type ReplaceDateWithStringType,
  type VehicleDTOExtended as _VehicleDTOExtended,
  type imgType
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { IconService } from '@inside-hub-app/ef-ng-pp-client'
import { TranslateService } from '@ngx-translate/core'
import { type Observable } from 'rxjs'
import { map, shareReplay, tap } from 'rxjs/operators'
// import { TNT } from '../mock/tnt'
// import { VEHICLES } from '../mock/allVehicles'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { type DealerDTO } from './dealers.service'
import { HashingService } from './hashing.service'
import { SharedService } from './shared.service'
import { TokenService } from './token.service'
import { isAfter, isSameDay, toDate } from 'date-fns'
import { LocalStorageService } from './local-storage.service'
export type VehicleDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDTO>
export type VehicleDealersDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDealersDTO
export type BrandDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.BrandDTO
export type ModelDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.ModelDTO
export type VehicleFrequentDriverDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleFrequentDriverDTO
export type TrackAndTraceVehicleDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.TrackAndTraceVehicleDTO>
export type ReasonEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDeactivateDTO.ReasonEnum
export type EquipmentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.EquipmentDTO
export type VehicleDTOExtended = _VehicleDTOExtended
export type FuelPriceDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.FuelPriceDTO

export enum VehicleStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum VehicleTab {
  GENERAL = 'general',
  DETAILS = 'details',
  DEALER = 'dealer',
  VEHICLE_HISTORY = 'vehicle-history',
  GALLERY = 'gallery',
  TRACKING = 'tracking'
}

export interface TrackAndTraceVehicleDTOExtended
  extends TrackAndTraceVehicleDTO {
  isHistory?: boolean
  status?: string
}

export interface VehiclesResponse {
  userVehicles: VehicleDTOExtended[]
  inactiveVehicles: VehicleDTOExtended[]
  tntVehicles: TrackAndTraceVehicleDTOExtended[]
}

export interface FrequentDriverDialogData {
  mostFrequentDriver: VehicleFrequentDriverDTO
  id: number
  ownershipId?: number
}

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  private readonly apiUrl
  token: string
  user: User

  constructor (
    private readonly imagesService: IconService,
    private readonly http: HttpClient,
    private readonly hashingService: HashingService,
    private readonly tokenService: TokenService,
    private readonly translate: TranslateService,
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  private currentGetVehicles: Observable<VehiclesResponse> = null

  setVehicleImage (imgData): {
    imgUrl: string
    imageType: imgType
  } {
    if (this.sharedService.stringExists(imgData.imageUrl)) {
      return {
        imgUrl: imgData.imageUrl,
        imageType: 'vehicleimage'
      }
    } else {
      return {
        imgUrl: this.imagesService.logoUrl(imgData.brand),
        imageType: 'brandlogo'
      }
    }
  }

  public getVehicles (
    includeImage?: boolean,
    includeDetails?: boolean,
    withTnt?: boolean
  ): Observable<VehiclesResponse> {
    if (this.currentGetVehicles === null) {
      const token = this.tokenService.getToken()
      let params = new HttpParams()
      params = params.append('c', this.hashingService.hashCode(token.cp_id))
      if (includeImage === true) {
        params = params.append('includeImage', 'true')
      }
      if (includeDetails === true) {
        params = params.append('includeDetails', 'true')
      }
      if (withTnt === true) {
        params = params.append('withTnt', 'true')
      }
      this.currentGetVehicles = this.http
        .get<VehiclesResponse>(this.apiUrl + '/vehicle/user-vehicles', {
        params
      })
        .pipe(
          map((vehicles: VehiclesResponse) => {
            // vehicles = VEHICLES as any // Mock data
            return {
              userVehicles: this.setVehicleArray(vehicles?.userVehicles, true),
              inactiveVehicles: this.setVehicleArray(vehicles?.userVehicles, false),
              tntVehicles: vehicles.tntVehicles
            }
          }),
          shareReplay(1, 100), // buffer for 100ms
          tap({ complete: () => (this.currentGetVehicles = null) }) // clear the observable
        )
    }

    return this.currentGetVehicles
  }

  public addUserVehicle (vehicle: VehicleDTO): Observable<VehicleDTO> {
    return this.http.post<VehicleDTO>(
      this.apiUrl + '/vehicle/user-vehicles',
      vehicle
    )
  }

  public addCompanyVehicle (vehicle: VehicleDTO): Observable<VehicleDTO> {
    return this.http.post<VehicleDTO>(
      this.apiUrl + '/vehicle/company-vehicles',
      vehicle
    )
  }

  public removeCompanyVehicle (ownershipId: number): Observable<VehicleDTO> {
    return this.http.delete<VehicleDTO>(
      this.apiUrl + '/vehicle/company-vehicles/' + ownershipId.toString()
    )
  }

  public updateLicencePlate (vehicle: VehicleDTO): Observable<VehicleDTO> {
    let params = new HttpParams()
    const lang = this.sharedService.currentLanguage()
    params = params.append('lang', lang)
    return this.http.put<VehicleDTO>(
      this.apiUrl + '/vehicle/user-vehicles/licence-plate',
      vehicle,
      {
        params
      }
    )
  }

  public getDealer (
    id: number,
    includeContacts?: boolean,
    brandName?: string,
    includeGoogleId?: boolean
  ): Observable<DealerDTO> {
    let params = new HttpParams()
    params =
      includeContacts === true
        ? params.append('includeContacts', 'true')
        : params
    params = this.sharedService.stringExists(brandName)
      ? params.append('brandName', 'true')
      : params
    params =
      includeGoogleId === true
        ? params.append('includeGoogleId', 'true')
        : params

    return this.http.get<DealerDTO>(this.apiUrl + '/dealers/' + id.toString(), {
      params
    })
  }

  public getVehicleDealers (
    vin: string,
    includeContacts: boolean,
    includeChatData: boolean,
    includeGoogleId: boolean
  ): Observable<VehicleDealersDTO> {
    const url = this.apiUrl + '/vehicle/dealers/' + vin
    let params = new HttpParams()
    params = includeContacts ? params.append('includeContacts', 'true') : params
    params = includeChatData ? params.append('includeChatData', 'true') : params
    params = includeGoogleId ? params.append('includeGoogleId', 'true') : params

    return this.http.get<VehicleDealersDTO>(url, {
      params
    })
  }

  public getUserDealers (
    includeGoogleId?: boolean
  ): Observable<VehicleDealersDTO[]> {
    const url = this.apiUrl + '/vehicle/dealers'
    let params = new HttpParams()
    if (includeGoogleId === true) {
      params = params.append('includeGoogleId', 'true')
    }
    return this.http.get<VehicleDealersDTO[]>(url, {
      params
    })
  }

  /**
   * Updates preferred vehicle dealers by VIN.
   *
   * @param salesDealerId number
   * @param serviceDealerId number
   */
  public updatePrefferedVehicleDealers (
    vin: string,
    salesDealerId: number,
    serviceDealerId: number
  ): Observable<VehicleDealersDTO> {
    const url =
      this.apiUrl +
      '/vehicle/dealers/' +
      vin +
      '?includeContacts=true&includeGoogleId=true'
    const params = {
      salesDealerId,
      serviceDealerId
    }
    return this.http.put<VehicleDealersDTO>(url, params)
  }

  /**
   * Update vehicle preferred dealer contacts
   * Updates preferred vehicle dealer contacts by VIN and preferred dealer id.
   * @param vin string
   * @param dealerId number
   * @param salesmanId number
   * @param salesAdvisorId number
   */
  public updateVehiclePrefferedDealerContacts (
    vin: string,
    dealerId: number,
    salesmanId: number,
    salesAdvisorId: number
  ): Observable<VehicleDealersDTO> {
    const url =
      this.apiUrl +
      '/vehicle/dealers/' +
      vin +
      '/contacts/' +
      dealerId.toString()
    const body = {
      salesmanId,
      salesAdvisorId
    }
    return this.http.put<VehicleDealersDTO>(url, body)
  }

  public getBrands (): Observable<BrandDTO[]> {
    return this.http.get<BrandDTO[]>(this.apiUrl + '/vehicle/brands').pipe(
      map((brands: BrandDTO[]) => {
        const excludedBrands: string[] =
          this.remoteConfigService.get('excludedBrands')
        return brands?.filter(brand => {
          if (
            this.sharedService.stringExists(brand.name) &&
            excludedBrands?.[0] != null
          ) {
            return (
              excludedBrands.findIndex(
                b => b.toLowerCase().trim() === brand.name.toLowerCase().trim()
              ) === -1
            )
          } else {
            return true
          }
        })
      })
    )
  }

  public getBrandModels (id: number): Observable<ModelDTO[]> {
    return this.http.get<ModelDTO[]>(
      this.apiUrl + '/vehicle/models/' + id.toString()
    )
  }

  public getFrequentDriver (
    id: number,
    ownershipId?: number
  ): Observable<VehicleFrequentDriverDTO> {
    let params = new HttpParams()
    if (ownershipId != null) {
      params = params.append('ownershipId', ownershipId.toString())
    }
    return this.http.get<VehicleFrequentDriverDTO>(
      this.apiUrl + '/vehicle/frequent-driver/' + id.toString(),
      {
        params
      }
    )
  }

  public updateFrequentDriver (
    id: number,
    driver: VehicleFrequentDriverDTO
  ): Observable<VehicleFrequentDriverDTO> {
    return this.http.put<VehicleFrequentDriverDTO>(
      this.apiUrl + '/vehicle/frequent-driver/' + id.toString(),
      driver
    )
  }

  public deleteFrequentDriver (
    id: number,
    ownershipId?: number
  ): Observable<VehicleFrequentDriverDTO> {
    let params = new HttpParams()
    if (ownershipId != null) {
      params = params.append('ownershipId', ownershipId.toString())
    }
    return this.http.delete<VehicleFrequentDriverDTO>(
      this.apiUrl + '/vehicle/frequent-driver/' + id.toString(),
      {
        params
      }
    )
  }

  public requestVehicleRemoval (
    vin: string,
    reason: ReasonEnum,
    language: string
  ): Observable<unknown> {
    let params = new HttpParams()
    params = params.append('lang', language)
    const body = {
      reason
    }
    return this.http.post(
      this.apiUrl + '/vehicle/user-vehicles/' + vin + '/deactivate',
      body,
      {
        params
      }
    )
  }

  public getVehicleDetails (
    vin: string,
    includeImage: boolean,
    lang: string
  ): Observable<VehicleDTOExtended> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    if (includeImage) {
      params = params.append('includeImage', 'true')
    }
    return this.http.get<VehicleDTOExtended>(
      this.apiUrl + '/vehicle/user-vehicles/' + vin,
      {
        params
      }
    )
  }

  public getVehicleNotifications (
    vin?: string
  ): Observable<VehicleFrequentDriverDTO[]> {
    const token = this.tokenService.getToken()
    let params = new HttpParams()
    params = params.append('c', this.hashingService.hashCode(token.cp_id))
    if (this.sharedService.stringExists(vin)) {
      params = params.append('vin', vin)
    }
    return this.http.get<VehicleFrequentDriverDTO[]>(
      this.apiUrl + '/vehicles-notifications',
      {
        params
      }
    )
  }

  public addNickname (params: {
    vin: string
    nickname: string
  }): Observable<VehicleDTO> {
    return this.http.put<VehicleDTO>(
      this.apiUrl + '/vehicle/user-vehicles/nickname',
      params
    )
  }

  public deleteNickname (vin: string): Observable<VehicleFrequentDriverDTO> {
    return this.http.delete<VehicleFrequentDriverDTO>(
      this.apiUrl + '/vehicle/user-vehicles/nickname/' + vin
    )
  }

  public setVehicleDealer (
    vehicles: VehicleDTOExtended[],
    userDealers: VehicleDealersDTO[]
  ): void {
    if (userDealers != null && vehicles != null) {
      vehicles.forEach(vehicle => {
        for (let i = 0; i < userDealers.length; i++) {
          const dealer = userDealers[i]
          if (
            this.sharedService.stringExists(dealer.vin) &&
            dealer.vin === vehicle.vin
          ) {
            // check if its the same dealer
            if (
              vehicle.dealers?.salesDealer?.id !== dealer.salesDealer?.id ||
              vehicle.dealers?.serviceDealer?.id !== dealer.serviceDealer?.id
            ) {
              vehicle.dealer = dealer.salesDealer
              vehicle.dealers = dealer
            }
            break
          }
        }
      })
    }
  }

  // track trace
  public getVehiclesTNT (): Observable<TrackAndTraceVehicleDTOExtended[]> {
    // return of(TNT) // Mock data
    return this.http.get<TrackAndTraceVehicleDTOExtended[]>(
      this.apiUrl + '/vehicle/tnt'
    )
  }

  public getFuelPrices (
    fuelType: string,
    zip?: string,
    coords?
  ): Observable<FuelPriceDTO[]> {
    // return of(FUEL_PRICES) // Mock data
    let params = new HttpParams()
    params = params.append('fuelType', fuelType)

    if (coords?.latitude != null) {
      params = params.append('lat', coords.latitude)
      params = params.append('lon', coords.longitude)
    } else {
      if (zip != null) {
        params = params.append('zip', zip)
      }
    }
    return this.http.get<FuelPriceDTO[]>(this.apiUrl + '/vehicle/fuel-prices', {
      params
    })
  }

  setStatus (data: TrackAndTraceVehicleDTOExtended[]): void {
    if (data?.[0] != null) {
      let index = 0
      data.forEach(vehicle => {
        // if the element doesnt have commissionNumber add temporary since its needed for navigation
        if (!this.sharedService.stringExists(vehicle.commissionNumber)) {
          vehicle.commissionNumber =
            'temporaryCommissionNumber-' + String(index)
          index++
        }

        // check for dates, set status
        const now = new Date()
        vehicle.status = undefined

        if (this.sharedService.stringExists(vehicle.registrationDate)) {
          const dateRegistration = toDate(new Date(vehicle.registrationDate))
          if (isAfter(now, dateRegistration) || isSameDay(now, dateRegistration)) {
            vehicle.status = 'registered'
          }
        }

        if (
          this.sharedService.stringExists(vehicle.deliveryDate) &&
          !this.sharedService.stringExists(vehicle.status)
        ) {
          const dateDelivery = toDate(new Date(vehicle.deliveryDate))
          if (isAfter(now, dateDelivery) || isSameDay(now, dateDelivery)) {
            vehicle.status = 'delivered'
          }
        }

        if (
          this.sharedService.stringExists(vehicle.buildDate) &&
          !this.sharedService.stringExists(vehicle.status)
        ) {
          const dateBuild = toDate(new Date(vehicle.buildDate))
          if (isAfter(now, dateBuild) || isSameDay(now, dateBuild)) {
            vehicle.status = 'production'
          }
        }

        if (
          this.sharedService.stringExists(vehicle.orderDate) &&
          !this.sharedService.stringExists(vehicle.status)
        ) {
          const dateOrder = toDate(new Date(vehicle.orderDate))
          if (isAfter(now, dateOrder) || isSameDay(now, dateOrder)) {
            vehicle.status = 'order'
          }
        }
      })
    }
  }

  setVehicleTNTHistory (
    allVehicles: VehicleDTOExtended[],
    inactiveVehicles: VehicleDTOExtended[],
    tnt: TrackAndTraceVehicleDTOExtended[]
  ): void {
    if ((allVehicles?.[0] != null || inactiveVehicles?.[0] != null) && tnt?.[0] != null) {
      allVehicles?.forEach(vehicle => {
        const history = tnt.find(el => {
          return el.vin === vehicle.vin || el.commissionNumber === vehicle.vin
        })
        if (history != null) {
          history.isHistory = true
        }
      })

      inactiveVehicles?.forEach(vehicle => {
        const history = tnt.find(el => {
          return el.vin === vehicle.vin || el.commissionNumber === vehicle.vin
        })
        if (history != null) {
          history.isHistory = true
        }
      })

      // CPEFG - 2231 - don't show already registered vehicles
      tnt.forEach(el => {
        if (el.status === 'registered') {
          el.isHistory = true
        }
      })
    }
  }

  setVehicleArray (
    vehicles: VehicleDTOExtended[],
    isActive: boolean
  ): VehicleDTOExtended[] {
    return vehicles
      ?.filter(vehicle => vehicle.active === isActive)
      ?.map(vehicle => {
        const imgObj = this.setVehicleImage(vehicle)

        return {
          ...vehicle,
          brandPicture: imgObj.imgUrl,
          pictureType: imgObj.imageType,
          leasingInfo: null,
          dealers: {
            serviceDealer: null,
            salesDealer: null,
            purchasedDealer: null
          }
        }
      })
  }

  getVehicleTab (): string {
    try {
      const vehicleNav = this.localStorageService.getVehicleNavigation()
      return this.sharedService.stringExists(vehicleNav)
        ? vehicleNav
        : VehicleTab.GENERAL
    } catch (error) {
      return VehicleTab.GENERAL
    }
  }
}
