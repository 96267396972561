import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  type Navigation
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import {
  CarmatoService,
  type CarmatoWatchlistDTOExtended
} from '../../../../services/carmato.service'
import { DataService } from '../../../../services/data.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDocumentsService } from '../../../../services/vehicle-documents.service'
import { VehicleTab, type VehicleDTOExtended } from '../../../../services/vehicles.service'
import { combineLatest } from 'rxjs'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-navigation',
  templateUrl: './vehicle-navigation.component.html'
})
export class RevolutionVehicleNavigationComponent implements OnInit, OnDestroy {
  navLinks
  navigationItems
  currentParent
  hideNavigation
  vin: string
  commissionNumber: string
  favorites: CarmatoWatchlistDTOExtended[] = []
  vinIsValid = false
  allVehicles: VehicleDTOExtended[] = []
  inactiveVehicles: VehicleDTOExtended[] = []
  vehicle: VehicleDTOExtended
  notCreatedByCPT = true
  homepage: string

  public screenSize: SCREEN_SIZE
  sub = {
    onVehicleVinChange: null,
    activatedRoute: null,
    activatedRouteURL: null,
    vehiclesLoaded: null,
    onResize: null,
    onWatchListLoaded: null,
    onInactiveVehiclesLoaded: null,
    combinedVehiclesSubscription: null
  }

  constructor (
    public dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    public router: Router,
    private readonly sharedService: SharedService,
    public resizeService: ResizeService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly carmatoService: CarmatoService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly logger: NGXLogger,
    private readonly vehicleDocumentService: VehicleDocumentsService,
    private readonly localStorageService: LocalStorageService,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSize = this.resizeService.screenSize
    this.navigationItems =
    this.remoteConfigService.getArrayValues<Navigation[]>('navigationVehicle')
    this.homepage = this.remoteConfigService.get('homepage')

    // this.navigation.items = this.navigation.items
  }

  get showVehicleDetails (): boolean {
    return this.vinIsValid && this.vehicle?.active === true
  }

  setPath (): void {
    this.navigationItems?.forEach((item: Navigation) => {
      if (item != null) {
        item.pathWithParams = this.sharedService.getRoute(item, this.vin)
        this.itemIsHidden(item)
        this.itemSetUnreadDocuments(item)
      }
    })

    // set findCurrentRoute if not exists
    if (this.currentParent == null) {
      this.findCurrentRoute()
    }
  }

  findCurrentRoute (): void {
    // iterate thru navigation and find path that matches current route
    this.navigationItems?.forEach((item: Navigation) => {
      if (item.pathWithParams === this.router.url) {
        this.setCurrentRoute(item)
      }
    })
  }

  setCurrentRoute (n: Navigation): void {
    this.currentParent = n
    this.cdRef.detectChanges()
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  setValues (): void {
    this.checkVin()
    this.setNgIfValues()
    this.setPath()
    this.activeRouteIsHidden()
  }

  sendContentModulesTabSwitchData (n: Navigation): void {
    const contentModuleLabel = n.translationKey
    const translation = this.sharedService.translateLink(contentModuleLabel)
    this.cptGtmService.sendContentModulesData(
      'Tab Switch',
      null,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Tab Switch',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
    this.econdaService.send({
      content: 'Tab switch',
      Target: ['Vehicle details tab switch', translation, 1, 'd']
    })
  }

  ngOnInit (): void {
    const userVehicles$ = this.dataService.onUserVehiclesLoaded
    const inactiveVehicles$ = this.dataService.onInactiveVehiclesLoaded

    this.sub.onVehicleVinChange = this.dataService.onVehicleVinChange.subscribe(
      vin => {
        this.vin = vin
        this.setValues()
      }
    )
    this.sub.activatedRoute = this.activatedRoute.params.subscribe(params => {
      if (params.vin != null) {
        if (this.sharedService.isComissionNumber(params.vin)) {
          this.commissionNumber = params.vin.replace('commissionNumber-', '')
        } else {
          // if tracking was selected previously show loader
          if (this.commissionNumber != null) {
            this.dataService.showLoader()
          }
          this.commissionNumber = undefined
          this.vin = params.vin
          // dont remove this line
          this.dataService.vehicleVinChanged(params.vin)
        }
      }
    })
    this.sub.activatedRouteURL = this.activatedRoute.url.subscribe(url => {
      // expand children if url changes
      this.findCurrentRoute()
      if (url != null) {
        try {
          const tab = url.pop().path
          this.localStorageService.setVehicleNavigation(this.getValidTab(tab))
        } catch (error) {
          // no need to log this
        }
      }
    })

    this.sub.combinedVehiclesSubscription = combineLatest([userVehicles$, inactiveVehicles$]).subscribe(
      ([allVehicles, inactiveVehicles]: [VehicleDTOExtended[], VehicleDTOExtended[]]) => {
        this.allVehicles = allVehicles
        this.inactiveVehicles = inactiveVehicles
        if (allVehicles.length > 0) {
          if (this.vin == null) {
            this.vin = allVehicles[0].vin
          }
        } else if (inactiveVehicles.length > 0) {
          if (this.vin == null) {
            this.vin = inactiveVehicles[0].vin
          }
        }

        this.setValues()
        if (!this.vinIsValid && this.commissionNumber == null) {
          void this.router.navigate([this.homepage])
        }
      }
    )
    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
    })
    this.sub.onWatchListLoaded =
      this.carmatoService.onWatchListLoaded.subscribe(favorites => {
        this.favorites = favorites
      })
  }

  getValidTab (tab: string): VehicleTab {
    return Object.values(VehicleTab).includes(tab as VehicleTab) ? (tab as VehicleTab) : VehicleTab.GENERAL
  }

  checkVin (): void {
    // check if user has vehicle with current vin
    if (this.vin != null) {
      this.vehicle = this.allVehicles?.find(({ vin }) => vin === this.vin) || this.inactiveVehicles?.find(({ vin }) => vin === this.vin)
      if (this.vehicle == null) {
        this.vinIsValid = false
      } else {
        this.vinIsValid = true
      }
    } else {
      this.vinIsValid = false
    }
  }

  setNgIfValues (): void {
    if (this.vehicle != null) {
      this.notCreatedByCPT =
        this.vehicle.createdByDataSource !== 'customer_portal'
    }
    this.cdRef.detectChanges()
  }

  itemIsHidden (item: Navigation): void {
    item.hidden = false
    if (item.ngIf?.[0] != null) {
      const condition = item.ngIf
      if (!(this[condition] === true) || this[condition] === 'undefined') {
        item.hidden = true
      }
    }
  }

  itemSetUnreadDocuments (item: Navigation): void {
    this.vehicleDocumentService.onUnreadVehicleDocuments.subscribe(count => {
      if (item.unreadDocuments >= 0) {
        item.unreadDocuments = count
      }
    })
  }

  activeRouteIsHidden (): void {
    // iterate thru navigation and find path that matches current route
    this.navigationItems?.forEach((item: Navigation) => {
      let firstNonHidden: Navigation
      if (!(item.hidden === true) && firstNonHidden == null) {
        firstNonHidden = item
      }
      if (
        item.pathWithParams === this.router.url &&
        item.hidden === true &&
        firstNonHidden != null
      ) {
        void this.router.navigate([firstNonHidden.pathWithParams])
        this.sendContentModulesTabSwitchData(firstNonHidden)
      }
    })
  }
}
