import { Component } from '@angular/core'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-vehicles-view',
  templateUrl: './vehicles-view.component.html'
})
export class VehiclesViewComponent {
  public hasNewFooter
  selectedVehicleStatusValue: string

  constructor(
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }

  onStatusChange(status: string): void {
    this.selectedVehicleStatusValue = status
  }
}
