<div
  class="RevolutionVehicleListComponent w-full relative bg-white"
  [ngClass]="mode"
  data-cy="revolution-vehicles-list"
>
  <div
    *ngIf="mode === 'summary' && hasVehiclesForMode"
    class="h-[271px] w-full bg-mat-ef-revolution-primary-500 absolute bottom-0"
  ></div>

  <div class="container px-4">
    <div
      class="w-full py-10 px-0"
      [ngClass]="{
        'md:pt-24': mode === 'summary',
        'md:py-16': mode !== 'summary'
      }"
    >
      <div
        class="w-full flex gap-6"
        [ngClass]="{
          'relative !flex-col md:flex-row': mode === 'summary',
          'flex-col': mode !== 'summary',
          'min-h-[480px]': hasVehiclesForMode
        }"
      >
        <!-- title -->
        <div
          class="flex flex-col text-center w-full"
          [ngClass]="{
            'md:text-left md:w-3/12 md:min-w-[250px]': mode === 'summary',
            '': mode !== 'summary'
          }"
        >
          <div
            class="text-mat-ef-revolution-primary-500 text-4xl md:text-5xl md:pb-12 font-canelablack"
            [ngClass]="{ 'md:text-6xl': mode !== 'summary' }"
          >
            {{ 'my-vehicles' | sharedTranslate }}
          </div>
          <div
            class="flex items-center justify-center flex-wrap w-full mt-10 md:mt-0 gap-6 md:gap-16"
            [ngClass]="{
              'hidden md:flex pb-12': mode === 'summary'
            }"
          >
            <button
              data-cy="add-new-vehicle-button"
              *ngIf="!loading"
              (click)="
                addNewVehicle();
                sendContentModulesCTAData(
                  'customerPortal.customer-portal.add-new-vehicle.title'
                )
              "
              mat-stroked-button
              class="text-mat-ef-revolution-secondary-500 border-2 border-mat-ef-revolution-secondary-500 text-base h-12"
              [ngClass]="{
                'px-8': mode !== 'summary'
              }"
            >
              <mat-icon class="mr-2" svgIcon="add"></mat-icon>
              <T
                str="Add vehicle"
                key="customerPortal.customer-portal.add-new-vehicle.title"
                tags="customer-portal, 3.1"
              ></T>
            </button>

            <div *ngIf="loading" class="w-full flex justify-center">
              <customer-portal-ef-spinner
                [color]="'mat-ef-revolution-grey-two-500'"
                [opacity]="0.7"
              >
              </customer-portal-ef-spinner>
            </div>

            @if (mode === "vehicles-view" && !loading) {
            <mat-radio-group
              class="flex flex-col md:flex-row gap-0 justify-center items-center rounded-md bg-mat-ef-revolution-supergrey-500 select-none min-w-[220px]"
              [(ngModel)]="selectedVehicleStatusValue"
            >
              <div
                id="vechileStatusToggle"
                class="flex w-full md:w-auto md:h-full"
              >
                <mat-radio-button
                  value="active"
                  [disabled]="disableToggle"
                  class="w-full md:w-auto md:h-full justify-center items-center rounded-md transition-all py-2 px-3 m-1 mb-0 md:mb-1"
                  [ngClass]="{
                    'bg-mat-ef-revolution-secondary-500': isActiveStatus,
                    'bg-transparent text-mat-ef-revolution-secondary-contrast-50':
                      !isActiveStatus,
                    'cursor-pointer': !disableToggle,
                    'cursor-not-allowed': disableToggle
                  }"
                  (click)="setVehicleToggleStatusValue(VehicleStatus.ACTIVE)"
                >
                  <T
                    str="Owned"
                    key="customerPortal.customer-portal.vehicle-toggle.owned"
                    tags="customer-portal, 3.24"
                  ></T>
                </mat-radio-button>
              </div>
              <div
                id="vechileStatusToggle"
                class="flex w-full md:w-auto md:h-full"
              >
                <mat-radio-button
                  value="inactive"
                  class="w-full md:w-auto md:h-full justify-center items-center rounded-md transition-all py-2 px-3 m-1 mt-0 md:mt-1"
                  [ngClass]="{
                    'bg-mat-ef-revolution-secondary-500': !isActiveStatus,
                    'bg-transparent text-mat-ef-revolution-secondary-contrast-50':
                      isActiveStatus,
                    'cursor-pointer': !disableToggle,
                    'cursor-not-allowed': disableToggle
                  }"
                  [disabled]="disableToggle"
                  (click)="setVehicleToggleStatusValue(VehicleStatus.INACTIVE)"
                >
                  <T
                    str="Previously owned"
                    key="customerPortal.customer-portal.vehicle-toggle.previously-owned"
                    tags="customer-portal, 3.1"
                  ></T>
                </mat-radio-button>
              </div>
            </mat-radio-group>
            }
          </div>
        </div>

        <div [hidden]="!loading" class="w-full">
          <ngx-skeleton-loader
            appearance="line"
            [count]="12"
            animation="progress"
          >
          </ngx-skeleton-loader>
        </div>

        <!-- VEHICLE NO -->

        <ng-container
          *ngIf="mode === 'summary' && !hasVehiclesForMode && !loading"
        >
          <div
            *ngIf="redirectToConsents()"
            (click)="openRedirectConsentsPopup()"
          >
            <ng-container *ngTemplateOutlet="noVehicles"></ng-container>
          </div>

          <!-- prettier-ignore -->
          <a
          [href]="'Link' | translate:{ _key: service?.linkKey, _tags: 'customer-portal, notranslate'}"
          target="_blank"
          *ngIf="!redirectToConsents()"
        >
        <ng-container *ngTemplateOutlet="noVehicles"></ng-container>
        </a>
        </ng-container>

        <!-- /VEHICLE NO -->

        <div
          [hidden]="loading"
          *ngIf="hasVehiclesForMode"
          [ngClass]="{
            'w-full pb-10 md:pb-[7rem] md:w-9/12 ': mode === 'summary',
            'w-full relative': mode !== 'summary',
            'md:px-28': mode !== 'summary' && showArrows
          }"
        >
          <div class="h-full w-full">
            @if(gliderShow === true) {
            <ngx-glide
              #RevolutionVehicleListComponent_ngxGlide
              [arrowLeftTemplate]="left"
              [arrowRightTemplate]="right"
              [startAt]="startAt || 0"
              [gap]="0"
              [breakpoints]="breakpoints"
              [listenToEvents]="false"
              [peek]="{ before: 0, after: 70 }"
              (resized)="recreate()"
              [showBullets]="showBullets"
              [showArrows]="showArrows"
              [bound]="bound"
              [ngClass]="{ showBullets: showBullets, fitGlider: fitGlider }"
              (touchstart)="swipe($event)"
              (touchend)="swipe($event)"
            >
              <ng-container *ngIf="isActiveStatus && hasActiveVehicles">
                <div
                  class="flex flex-col px-2 md:px-3 py-0 h-auto min-w-[220px] max-w-[360px] min-h-[338px]"
                  [ngClass]="{ 'pb-5': mode !== 'summary' }"
                  *ngFor="let vehicle of vehicles; let i = index"
                  (click)="
                    sendContentModulesVehicleTeaserData(
                      'shared.my-vehicles',
                      vehicle.brand + ' ' + vehicle.model
                    )
                  "
                >
                  <customer-portal-app-vehicle-card
                    class="w-full h-full"
                    [vehicle]="vehicle"
                    [vehicleNotifications]="vehicleNotifications"
                    [vin]="vin"
                    [mode]="mode"
                  >
                  </customer-portal-app-vehicle-card>
                </div>
              </ng-container>

              <ng-container *ngIf="!isActiveStatus && hasInactiveVehicles">
                <div
                  class="flex flex-col px-2 md:px-3 py-0 h-auto min-w-[220px] max-w-[360px] min-h-[338px]"
                  [ngClass]="{ 'pb-5': mode !== 'summary' }"
                  *ngFor="let vehicle of inactiveVehicles; let i = index"
                  (click)="
                    sendContentModulesVehicleTeaserData(
                      'shared.my-vehicles',
                      vehicle.brand + ' ' + vehicle.model
                    )
                  "
                >
                  <customer-portal-app-vehicle-card
                    class="w-full h-full"
                    [vehicle]="vehicle"
                    [vehicleNotifications]="vehicleNotifications"
                    [vin]="vin"
                    [mode]="mode"
                  >
                  </customer-portal-app-vehicle-card>
                </div>
              </ng-container>

              <ng-container *ngIf="isActiveStatus && hasTNTVehicles">
                <div
                  class="flex flex-col px-3 py-0 h-auto min-w-[220px] max-w-[360px]"
                  [ngClass]="{ 'pb-5': mode !== 'summary' }"
                  *ngFor="let vehicle of vehiclesTNT; let i = index"
                  (click)="
                    sendContentModulesVehicleTeaserData(
                      'shared.my-vehicles',
                      vehicle.brand + ' ' + vehicle.model
                    )
                  "
                >
                  <customer-portal-app-vehicle-card
                    data-cy="vehicle-card-tnt"
                    class="w-full h-full"
                    [vehicleTNT]="vehicle"
                    [commissionNumber]="commissionNumber"
                    [mode]="mode"
                  >
                  </customer-portal-app-vehicle-card>
                </div>
              </ng-container>
            </ngx-glide>
            }
          </div>
        </div>

        <div class="flex w-full px-2 md:hidden" *ngIf="mode === 'summary'">
          <button
            *ngIf="!loading"
            (click)="
              addNewVehicle();
              sendContentModulesCTAData(
                'customerPortal.customer-portal.add-new-vehicle.title'
              )
            "
            mat-stroked-button
            class="revolution addNewVehicle border-2 text-base h-12 w-full"
            [ngClass]="{
              'border-white text-white ': hasVehiclesForMode,
              'text-mat-ef-revolution-secondary-500 border-mat-ef-revolution-secondary-500':
                !hasVehiclesForMode
            }"
          >
            <mat-icon class="mr-2" svgIcon="add"></mat-icon>

            <T
              str="Add vehicle"
              key="customerPortal.customer-portal.add-new-vehicle.title"
              tags="customer-portal, 3.1"
            ></T>
          </button>
          <div *ngIf="loading" class="w-full flex justify-center">
            <customer-portal-ef-spinner
              [color]="'mat-ef-revolution-grey-two-500'"
              [opacity]="0.7"
            >
            </customer-portal-ef-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #left>
  <div
    class="hidden arrowTemplate leftArrow md:flex justify-center items-center"
    [ngClass]="{
      'white bottomArrows': mode === 'summary',
      'sideArrows !left-0': mode !== 'summary'
    }"
    (click)="
      sendContentModulesSliderData(
        'Left',
        'shared.my-vehicles',
        'Revolution vehicle list'
      )
    "
  >
    <mat-icon svgIcon="arrow_left"></mat-icon>
  </div>
</ng-template>

<ng-template #right>
  <div
    class="hidden arrowTemplate rightArrow md:flex justify-center items-center"
    [ngClass]="{
      'white bottomArrows': mode === 'summary',
      'sideArrows !right-0': mode !== 'summary'
    }"
    (click)="
      sendContentModulesSliderData(
        'Right',
        'shared.my-vehicles',
        'Revolution vehicle list'
      )
    "
  >
    <mat-icon svgIcon="arrow_right"></mat-icon>
  </div>
</ng-template>

<ng-template #noVehicles>
  <div
    class="relative flex flex-col lg:flex-row gap-6 p-3 bg-mat-ef-revolution-grey-two-50 cursor-pointer rounded-lg hover:!shadow-none"
    [ngClass]="{ 'opacity-50': redirectToConsents() }"
  >
    <div class="flex">
      <img
        [alt]="service?.categoryKey"
        [src]="service?.imageUrl"
        class="w-auto h-auto max-w-full lg:max-h-[184px] lg:min-h-[184px] lg:h-[184px] object-contain m-auto rounded"
      />
    </div>
    <div class="flex-1 grid gap-4 py-4">
      <div
        class="font-canelablack text-mat-ef-revolution-primary-500 md:text-3xl text-2xl break-words"
      >
        <T
          str="Text"
          [key]="service?.textKey"
          tags="customer-portal, notranslate"
        ></T>
      </div>
      <div class="flex gap-2 flex-col lg:flex-row">
        <div class="break-words">
          <T
            str="Description"
            [key]="service?.descriptionKey"
            tags="customer-portal, notranslate"
          ></T>
        </div>
      </div>
    </div>
  </div>
</ng-template>
