/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { debounceTime, Subject } from 'rxjs'
import { SharedService } from './shared.service'
import { type ArticleResponse } from './dash-eco.service'
import { type VehicleGalleryDTO } from './gallery.service'
import { type RecallDTO } from './recalls.service'
import { type ServicePackageDTO } from './service-package.service'
import { type TiresStockDTO, type TiresAppointmentDTO } from './tires.service'
import {
  type BasicDocumentDTOExtended,
  type VehiclePromotionDocumentDTOExtended
} from './vehicle-documents.service'
import { type Warranty } from './warranty.service'
import { type DealerDTO } from './dealers.service'
import {
  type BrandDTO,
  type TrackAndTraceVehicleDTOExtended,
  type VehicleDealersDTO,
  type VehicleDTOExtended,
  type VehicleFrequentDriverDTO
} from './vehicles.service'
import { type VehicleReminderDTO } from './vehicle-reminders.service'
import {
  type VehicleInsuranceDTO
} from '@inside-hub-app/customer-portal-shared'
import { type VehicleNotificationsDTO } from './vehicle-notifications.service'
import { type UpcomingAppointmentDTO } from './appointments.service'

export interface LocalStorageVehicle extends VehicleDTOExtended {
  relations?: {
    warranty?: Warranty
    servicePackage?: ServicePackageDTO[]
    documents?: BasicDocumentDTOExtended[]
    galleries?: VehicleGalleryDTO[]
    tires?: TiresStockDTO
    promotions?: VehiclePromotionDocumentDTOExtended[]
    tireAppointmentLink?: TiresAppointmentDTO
    recalls?: RecallDTO
    dealers?: VehicleDealersDTO
    vehicleDetails?: VehicleDTOExtended
    frequentDriver?: VehicleFrequentDriverDTO
    brandDealers?: DealerDTO[]
    reminders?: VehicleReminderDTO[]
    insurance?: VehicleInsuranceDTO
  }
}

export interface LocalStorageData {
  data: LocalStorageUserData[]
  sessionState: string
  lang?: string
  vehicleNavigation?: string
}

export interface LocalStoragePwaData {
  appInstalled: boolean
}

export interface LocalStorageUserData {
  userId: number
  data: {
    vehicles: LocalStorageVehicle[]
    inactiveVehicles: LocalStorageVehicle[]
    userDealers: VehicleDealersDTO[]
    articles: ArticleResponse
    allBrands?: BrandDTO[]
    allDealers?: DealerDTO[]
    myDocuments?: BasicDocumentDTOExtended[]
    invoices?: BasicDocumentDTOExtended[]
    vehiclesTNT?: TrackAndTraceVehicleDTOExtended[]
    pwa?: LocalStoragePwaData
    notifications?: VehicleNotificationsDTO
    upcomingAppointments?: UpcomingAppointmentDTO[]
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly CPT_IDB = {
    db: null as IDBDatabase | null,

    async initialize () {
      return await new Promise((resolve, reject) => {
        const request = indexedDB.open('cptDatabase', 1)

        request.onupgradeneeded = () => {
          const db = request.result
          if (!db.objectStoreNames.contains('cptStore')) {
            db.createObjectStore('cptStore')
          }
        }

        request.onsuccess = () => {
          this.db = request.result
          resolve(true)
        }

        request.onerror = () => {
          reject(new Error(request.error?.message || 'IndexedDB initialization failed'))
        }
      })
    },

    async transaction (mode: IDBTransactionMode, callback: (store: IDBObjectStore) => IDBRequest) {
      return await new Promise((resolve, reject) => {
        if (!this.db) {
          reject(new Error('Database not initialized'))
          return
        }
        const tx = this.db.transaction('cptStore', mode)
        const store = tx.objectStore('cptStore')
        const request = callback(store)

        request.onsuccess = () => { resolve(request.result) }
        request.onerror = () => { reject(new Error(request.error?.message || 'IndexedDB operation failed')) }
      })
    },

    async get (key) {
      return this.transaction('readonly', store => store.get(key))
    },

    async set (key, value) {
      return this.transaction('readwrite', store => store.put(value, key))
    },

    async remove (key) {
      return this.transaction('readwrite', store => store.delete(key))
    }
  }

  cptData: LocalStorageData = {
    data: [],
    sessionState: null
  }

  sessionState
  lang
  vehicleNavigation

  user: User

  // data changed
  private readonly _localStorageDataChanged = new Subject<string>()
  onLocalStorageDataChanged = this._localStorageDataChanged.asObservable()

  private readonly _setData = new Subject()
  onSetData = this._setData.asObservable()

  constructor (
    private readonly sharedService: SharedService,
    private readonly window: Window,
    private readonly logger: NGXLogger
  ) {
    // using local storage for sessionState since its not async
    try {
      this.sessionState = localStorage.getItem('sessionState')
    } catch (error) {}

    void this.initIDB()
    this.onSetData.pipe(debounceTime(2000)).subscribe(() => {
      // save data to IDB
      this.setDataInner()
    })
  }

  async initIDB (): Promise<void> {
    try {
      // initialize database
      await this.CPT_IDB.initialize()

      // get cpt data
      void this.getCptData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async getCptData (): Promise<void> {
    try {
      // get cpt data
      const data = (await this.CPT_IDB.get('cpt')) as LocalStorageData
      if (data != null) {
        this.cptData = data
      } else {
        // if data doesnt exists add empty object to DB
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  setData (): void {
    this._setData.next(true)
  }

  setDataInner (): void {
    try {
      this.cptData.sessionState = this.sessionState
      if (this.sharedService.stringExists(this.lang)) {
        this.cptData.lang = this.lang
      }
      if (this.sharedService.stringExists(this.vehicleNavigation)) {
        this.cptData.vehicleNavigation = this.vehicleNavigation
      }
      void this.CPT_IDB.set('cpt', this.cptData)
    } catch (error) {
      this.logger.debug(error)
    }
  }

  getHideOnesignalNotificationPrompt (): string {
    return localStorage.getItem('hideOnesignalNotificationPrompt')
  }

  getSessionState (): string {
    const sessionState = this.sharedService.stringExists(
      this.cptData.sessionState
    )
      ? this.cptData.sessionState
      : this.sessionState
    return sessionState
  }

  setSessionState (sessionState: string): void {
    this.sessionState = sessionState
    this.cptData.sessionState = sessionState
    localStorage.setItem('sessionState', sessionState)
  }

  getLang (): string {
    const lang = this.sharedService.stringExists(this.cptData.lang)
      ? this.cptData.lang
      : this.lang

    return lang
  }

  setLang (lang: string): void {
    if (this.sharedService.stringExists(lang)) {
      this.lang = lang
      this.cptData.lang = lang
    }
  }

  getVehicleNavigation (): string {
    const vehicleNavigation = this.sharedService.stringExists(
      this.cptData.vehicleNavigation
    )
      ? this.cptData.vehicleNavigation
      : this.vehicleNavigation

    return vehicleNavigation
  }

  setVehicleNavigation (vehicleNavigation: string): void {
    if (this.sharedService.stringExists(vehicleNavigation)) {
      this.vehicleNavigation = vehicleNavigation
      this.cptData.vehicleNavigation = vehicleNavigation
    }
  }

  setUser (user: User): void {
    this.user = user
  }

  getEmptyUserObject (user): LocalStorageUserData {
    return {
      userId: user.contactId,
      data: {
        articles: null,
        vehicles: null,
        userDealers: null,
        vehiclesTNT: null,
        inactiveVehicles: null
      }
    }
  }

  // Return local storage data for user
  async getUserData (user: User): Promise<LocalStorageUserData> {
    return await new Promise<LocalStorageUserData>((resolve, reject) => {
      try {
        const dataExist = Boolean(this.cptData.data)
        if (!dataExist) {
          this.cptData.data = []
          this.setData()
        }
        const userData = this.cptData.data.find(
          ({ userId }) => userId === user.contactId
        )
        const userDataExist = Boolean(userData)
        if (!userDataExist) {
          this.cptData.data.push(this.getEmptyUserObject(user))
          this.setData()
        }
        resolve(userData)
      } catch (error) {
        reject(error)
      }
    })
  }

  /** VEHICLES */
  async getVehicles (): Promise<LocalStorageVehicle[]> {
    return await new Promise<LocalStorageVehicle[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.vehicles)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async getVehicleByVin (vinnumber: string): Promise<LocalStorageVehicle> {
    return await new Promise<LocalStorageVehicle>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(
            userData?.data?.vehicles?.find(({ vin }) => vin === vinnumber)
          )
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setVehicles (v: VehicleDTOExtended[]): Promise<void> {
    if (this.user != null) {
      try {
        const userData = await this.getUserData(this.user)

        const vehiclesOld =
          userData.data.vehicles != null ? userData.data.vehicles : []
        const vehicles = []
        v.forEach(vehicle => {
          const vehicleOld = vehiclesOld.find(el => {
            return el.vin === vehicle.vin
          })
          const vehicleExtended = vehicle as LocalStorageVehicle
          vehicleExtended.relations =
            vehicleOld != null ? vehicleOld.relations : {}
          vehicles.push(vehicleExtended)
        })
        userData.data.vehicles = vehicles
        this.setData()
      } catch (error) {
        this.logger.debug(error)
      }
    }
  }

  async getAllBrands (): Promise<BrandDTO[]> {
    return await new Promise<BrandDTO[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.allBrands)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setAllBrands (b: BrandDTO[]): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      userData.data.allBrands = b
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async getVehiclesTNT (): Promise<TrackAndTraceVehicleDTOExtended[]> {
    return await new Promise<TrackAndTraceVehicleDTOExtended[]>(
      (resolve, reject) => {
        this.getUserData(this.user)
          .then((userData: LocalStorageUserData) => {
            resolve(userData?.data?.vehiclesTNT)
          })
          .catch(error => {
            reject(error)
          })
      }
    )
  }

  async setVehiclesTNT (v: TrackAndTraceVehicleDTOExtended[]): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      userData.data.vehiclesTNT = v
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async getInactiveVehicles (): Promise<LocalStorageVehicle[]> {
    return await new Promise<LocalStorageVehicle[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.inactiveVehicles)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setInactiveVehicles (v: VehicleDTOExtended[]): Promise<void> {
    if (this.user != null) {
      try {
        const userData = await this.getUserData(this.user)

        const vehiclesOld =
          userData.data.inactiveVehicles != null ? userData.data.inactiveVehicles : []
        const vehicles = []
        v.forEach(vehicle => {
          const vehicleOld = vehiclesOld.find(el => {
            return el.vin === vehicle.vin
          })
          const vehicleExtended = vehicle as LocalStorageVehicle
          vehicleExtended.relations =
            vehicleOld != null ? vehicleOld.relations : {}
          vehicles.push(vehicleExtended)
        })
        userData.data.inactiveVehicles = vehicles
        this.setData()
      } catch (error) {
        this.logger.debug(error)
      }
    }
  }

  /** DEALERS */
  async getUserDealers (): Promise<VehicleDealersDTO[]> {
    return await new Promise<VehicleDealersDTO[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.userDealers)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setUserDealers (d: VehicleDealersDTO[]): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      let dealers = userData.data.userDealers
      dealers = []
      d.forEach(dealer => {
        dealers.push(dealer)
      })
      userData.data.userDealers = dealers
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async getAllDealers (): Promise<DealerDTO[]> {
    return await new Promise<DealerDTO[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.allDealers)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setAllDealers (d: DealerDTO[]): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      userData.data.allDealers = d
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async setVehicleBrandDealers (
    vin: string,
    dealers: DealerDTO[]
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        vehicle.relations.brandDealers = dealers
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  /** ARTICLES */
  async getArticles (): Promise<ArticleResponse> {
    return await new Promise<ArticleResponse>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.articles)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setArticles (a: ArticleResponse): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      userData.data.articles = a
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // my documents
  async getMyDocuments (): Promise<BasicDocumentDTOExtended[]> {
    return await new Promise<BasicDocumentDTOExtended[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.myDocuments)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setMyDocuments (
    documents: BasicDocumentDTOExtended[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      const myDocuments = userData.data.myDocuments
      if (checkIfEqual === true) {
        this.checkIfEqual(
          myDocuments != null ? myDocuments : [],
          documents != null ? documents : [],
          'myDocuments'
        )
      }
      userData.data.myDocuments = documents
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Invoices
  async getInvoices (): Promise<BasicDocumentDTOExtended[]> {
    return await new Promise<BasicDocumentDTOExtended[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.invoices)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setInvoices (
    invoiceDocuments: BasicDocumentDTOExtended[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      const invoices = userData.data.invoices
      if (checkIfEqual === true) {
        this.checkIfEqual(
          invoices != null ? invoices : [],
          invoiceDocuments != null ? invoiceDocuments : [],
          'invoices'
        )
      }
      userData.data.invoices = invoiceDocuments
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Notifications
  async getVehicleNotifications (): Promise<VehicleNotificationsDTO> {
    return await new Promise<VehicleNotificationsDTO>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.notifications)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setVehicleNotifications (
    vehicleNotifications: VehicleNotificationsDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      const notifications = userData.data.notifications
      if (checkIfEqual === true) {
        this.checkIfEqual(
          notifications != null ? notifications : { attentionsCount: 0 },
          vehicleNotifications != null ? vehicleNotifications : { attentionsCount: 0 },
          'notifications'
        )
      }
      userData.data.notifications = vehicleNotifications
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Upcoming appointments
  async getUpcomingAppointments (): Promise<UpcomingAppointmentDTO[]> {
    return await new Promise<UpcomingAppointmentDTO[]>((resolve, reject) => {
      this.getUserData(this.user)
        .then((userData: LocalStorageUserData) => {
          resolve(userData?.data?.upcomingAppointments)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async setUpcomingAppointments (
    upcomingAppointments: UpcomingAppointmentDTO[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const userData = await this.getUserData(this.user)
      const upcomingAppointments_ = userData.data.upcomingAppointments
      if (checkIfEqual === true) {
        this.checkIfEqual(
          upcomingAppointments_ != null ? upcomingAppointments_ : [],
          upcomingAppointments != null ? upcomingAppointments : [],
          'upcomingAppointments'
        )
      }
      userData.data.upcomingAppointments = upcomingAppointments
      this.setData()
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // VEHICLE data

  async getVehicleDocuments (vin: string): Promise<BasicDocumentDTOExtended[]> {
    return await new Promise<BasicDocumentDTOExtended[]>((resolve, reject) => {
      this.getVehicleByVin(vin)
        .then(vehicle => {
          resolve(vehicle.relations.documents)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Save documents
  async setVehicleDocuments (
    vin: string,
    documents: BasicDocumentDTOExtended[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.documents != null
              ? vehicle.relations.documents
              : [],
            documents != null ? documents : [],
            'documents'
          )
        }
        vehicle.relations.documents = documents
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save gallery
  async setGalleries (
    vin: string,
    galleries: VehicleGalleryDTO[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.galleries != null
              ? vehicle.relations.galleries
              : [],
            galleries != null ? galleries : [],
            'galleries'
          )
        }
        vehicle.relations.galleries = galleries
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save warranty
  async setWarranty (
    vin: string,
    warranty: Warranty,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.warranty != null
              ? vehicle.relations.warranty
              : {},
            warranty != null ? warranty : {},
            'warranty'
          )
        }
        vehicle.relations.warranty = warranty
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save insurance
  async setInsurance (
    vin: string,
    insurance: VehicleInsuranceDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.insurance != null
              ? vehicle.relations.insurance
              : {},
            insurance != null ? insurance : {},
            'insurance'
          )
        }
        vehicle.relations.insurance = insurance
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  async setReminders (
    vin: string,
    reminders: VehicleReminderDTO[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.reminders != null
              ? vehicle.relations.reminders
              : [],
            reminders != null ? reminders : [],
            'reminders'
          )
        }
        vehicle.relations.reminders = reminders
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save Service Packages
  async setServicePackage (
    vin: string,
    servicePackage: ServicePackageDTO[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.servicePackage != null
              ? vehicle.relations.servicePackage
              : [],
            servicePackage != null ? servicePackage : [],
            'servicePackage'
          )
        }
        vehicle.relations.servicePackage = servicePackage
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save promotions
  async setPromotions (
    vin: string,
    promotions: VehiclePromotionDocumentDTOExtended[],
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.promotions != null
              ? vehicle.relations.promotions
              : [],
            promotions != null ? promotions : [],
            'promotions'
          )
        }
        vehicle.relations.promotions = promotions
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save Vehicle tire appointment link
  /**
   *
   * now it covers all services, not only tires
   */
  async setTiresAppintment (
    vin: string,
    tireAppointmentLink: TiresAppointmentDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.tireAppointmentLink != null
              ? vehicle.relations.tireAppointmentLink
              : {},
            tireAppointmentLink != null ? tireAppointmentLink : {},
            'tireAppointmentLink'
          )
        }
        vehicle.relations.tireAppointmentLink = tireAppointmentLink
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save tires
  async setTires (
    vin: string,
    tires: TiresStockDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.tires != null ? vehicle.relations.tires : {},
            tires != null ? tires : {},
            'tires'
          )
        }
        vehicle.relations.tires = tires
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save recalls
  async setRecalls (
    vin: string,
    recalls: RecallDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.recalls != null ? vehicle.relations.recalls : {},
            recalls != null ? recalls : {},
            'recalls'
          )
        }
        vehicle.relations.recalls = recalls
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save vehicle dealers
  async setVehicleDealers (
    vin: string,
    dealers: VehicleDealersDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.dealers != null ? vehicle.relations.dealers : {},
            dealers != null ? dealers : {},
            'dealers'
          )
        }
        if (dealers?.salesDealer != null) {
          vehicle.dealer = this.sharedService.deepCopy(dealers.salesDealer)
          vehicle.dealers = this.sharedService.deepCopy(dealers)
        }
        vehicle.relations.dealers = dealers
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save Vehicle details
  async setVehicleDetails (
    vin: string,
    vehicleDetails: VehicleDTOExtended,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          let vehicleDetailsCopy
          try {
            vehicleDetailsCopy = this.sharedService.deepCopy(
              vehicle.relations.vehicleDetails
            )
            delete vehicleDetailsCopy.dealers
            delete vehicleDetailsCopy.brandPicture
            delete vehicleDetailsCopy.leasingInfo
            delete vehicleDetailsCopy.pictureType
          } catch (error) {}
          this.checkIfEqual(
            vehicleDetailsCopy != null ? vehicleDetailsCopy : {},
            vehicleDetails != null ? vehicleDetails : {},
            'vehicleDetails'
          )
        }
        if (vehicleDetails != null) {
          // need to update main vehicle propertys
          Object.keys(vehicleDetails).forEach(key => {
            vehicle[key] = vehicleDetails[key]
          })
        }
        vehicle.relations.vehicleDetails = vehicleDetails
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  // Save Frequent driver
  async setFrequentDriver (
    vin: string,
    frequentDriver: VehicleFrequentDriverDTO,
    checkIfEqual?: boolean
  ): Promise<void> {
    try {
      const vehicle = await this.getVehicleByVin(vin)
      if (vehicle != null) {
        if (checkIfEqual === true) {
          this.checkIfEqual(
            vehicle.relations.frequentDriver != null
              ? vehicle.relations.frequentDriver
              : {},
            frequentDriver != null ? frequentDriver : {},
            'frequentDriver'
          )
        }
        vehicle.relations.frequentDriver = frequentDriver
        this.setData()
      }
    } catch (error) {
      this.logger.debug(error)
    }
  }

  checkIfEqual (oldValue, newValue, name): void {
    const ifEqual = this.sharedService.isEqual(oldValue, newValue)
    if (!ifEqual) {
      this._localStorageDataChanged.next(name)
    }
  }
}
