<ng-container *ngIf="showWidget">
  <div
    class="container pb-14 px-4"
    *ngIf="!vehicle?.business"
    [ngClass]="{ 'pt-14': selectedVehicleStatusValue === 'inactive' }"
  >
    <mat-card
      class="flex items-center flex-col md:flex-row gap-3 justify-between py-2.5 px-9 rounded-md w-full lg:w-3/5 m-auto"
    >
      <div class="w-full ml-5">
        <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
          <div
            class="flex font-efSans text-xl font-bold text-mat-ef-revolution-primary-500"
          >
            <span>
              <T
                str="Change of ownership"
                key="customerPortal.customer-portal.vehicle.remove"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
        </div>
        <div
          *ngIf="isActiveVehicle"
          class="text-sm text-efSans text-mat-ef-revolution-primary-500"
        >
          <T
            str="If you no longer own this vehicle, you can remove it."
            key="customerPortal.customer-portal.vehicle.remove.description"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          *ngIf="!isActiveVehicle"
          class="text-sm text-efSans text-mat-ef-revolution-primary-500"
        >
          <T
            str="You have already requested change of ownership on { Date }. No further request needed."
            key="customerPortal.customer-portal.vehicle.remove-already-requested"
            tags="customer-portal, 3.8"
            [vars]="{ Date: deactivateRequestDateString }"
          ></T>
        </div>
      </div>

      <div
        *ngIf="isActiveVehicle"
        data-cy="remove-vehicle-button"
        class="btn-delete-vehicle"
        (click)="
          openVehicleRemovalPopup();
          sendContentModulesMiniTeaserData(
            'customerPortal.customer-portal.vehicle.warranty.requestNow'
          )
        "
      >
        <T
          str="Ask now"
          key="customerPortal.customer-portal.vehicle.warranty.requestNow"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </mat-card>
  </div>

  <div class="container pb-14" *ngIf="vehicle?.business">
    <mat-card
      class="flex items-center flex-col md:flex-row gap-3 justify-between py-2.5 px-9 rounded-md w-full lg:w-3/5 m-auto"
    >
      <div class="w-full ml-5">
        <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
          <div
            class="flex font-efSans text-xl font-bold text-mat-ef-revolution-primary-500"
          >
            <span>
              <T
                str="Remove company vehicle"
                key="customerPortal.customer-portal.company-vehicle.remove"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
        </div>
        <div class="text-sm text-efSans text-mat-ef-revolution-primary-500">
          <T
            str="If you no longer use this vehicle, you can remove it."
            key="customerPortal.customer-portal.company-vehicle.remove.description"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <div
        data-cy="remove-vehicle-button"
        class="btn-delete-vehicle"
        (click)="openCompanyVehicleRemovalPopup()"
      >
        <T
          str="Removed"
          key="customerPortal.customer-portal.company-vehicle.remove.button"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </mat-card>
  </div>
</ng-container>
