import {
  ChangeDetectorRef,
  Component,
  Input,
  type OnDestroy,
  type OnInit
} from '@angular/core'
import { Router } from '@angular/router'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import {
  TrackAndTraceVehicleDTOExtended,
  VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
export type ScopeEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.dashboard.dto.NotificationDTO.ScopeEnum
@Component({
  selector: 'customer-portal-app-vehicle-card',
  templateUrl: './vehicle-card.component.html'
})
export class VehicleCardComponent implements OnInit, OnDestroy {
  @Input() vehicle: VehicleDTOExtended
  @Input() vehicleTNT: TrackAndTraceVehicleDTOExtended
  @Input() vehicleNotifications
  @Input() mode
  @Input() vin
  @Input() commissionNumber: string

  vehicleNav: string
  dateFormat: string

  lang
  user: User
  licensePlateMask
  showMask = false

  sub = {
    onUserLoaded: null,
    onLanguageChange: null,
    onUserTrackingLoaded: null
  }

  vehicleTrackingHistory: TrackAndTraceVehicleDTOExtended
  constructor (
    public sharedService: SharedService,
    private readonly router: Router,
    private readonly data: DataService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly vehiclesService: VehiclesService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  get tab (): string {
    return this.vehiclesService.getVehicleTab()
  }

  ngOnInit (): void {
    this.licensePlateMask = this.sharedService.setLicensePlateMask()

    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.lang = undefined
      this.user = undefined
      this.cdRef.detectChanges()
      this.user = user
      this.lang =
        this.user != null && user.language != null && user.language.length !== 0
          ? user.language.find(s => s.primary).language
          : ''
    })
    this.sub.onLanguageChange = this.data.onLanguageChange.subscribe(
      newLanguage => {
        this.lang = undefined
        this.cdRef.detectChanges()
        this.lang = newLanguage.toUpperCase()
      }
    )

    this.sub.onUserTrackingLoaded = this.data.onUserTrackingLoaded.subscribe(
      (data: TrackAndTraceVehicleDTOExtended[]) => {
        this.vehicleTrackingHistory = undefined
        if (this.vehicle != null && data?.[0] != null) {
          // find tracking history
          this.vehicleTrackingHistory = data.find(el => {
            return (
              el.vin === this.vehicle.vin ||
              el.commissionNumber === this.vehicle.vin
            )
          })
        }
      }
    )

    this.showMask = this.sharedService.showLicensePlateMask(
      this.vehicle?.licensePlate
    )
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  showNotification (scope: ScopeEnum): boolean {
    if (
      this.vehicleNotifications != null &&
      ((scope === 'LOAN' &&
        Boolean(this.remoteConfigService.get('notifications.leasing'))) ||
        (scope === 'RECALL' &&
          Boolean(this.remoteConfigService.get('notifications.recalls'))) ||
        (scope === 'WARRANTY' &&
          Boolean(this.remoteConfigService.get('notifications.warranty'))) ||
        (scope === 'EXTENDED_WARRANTY' &&
          Boolean(this.remoteConfigService.get('notifications.warranty'))) ||
        (scope === 'SERVICE_PACKAGE' &&
          Boolean(
            this.remoteConfigService.get('notifications.servicePackage')
          )) ||
        (scope === 'TIRES_WARRANTY' &&
          Boolean(
            this.remoteConfigService.get('notifications.tyresWarranty')
          )) ||
        (scope === 'TIRES_HEALTH' &&
          Boolean(this.remoteConfigService.get('notifications.tyresHealth'))))
    ) {
      return true
    } else {
      return false
    }
  }

  goToVehicle (v: VehicleDTOExtended): void {
    this.sharedService.goToVehicle(v, this.tab)
  }

  goToVehicleTNT (v: TrackAndTraceVehicleDTOExtended): void {
    this.sharedService.goToVehicleTNT(v, this.tab)
  }

  sendContentModulesVehicleTeaserData (
    contentModuleLabel: string,
    text: string
  ): void {
    const label =
      this.sharedService.translateLink(contentModuleLabel) + '|' + text
    this.cptGtmService.sendContentModulesData(
      'vehicle teaser',
      null,
      label,
      'Tab Switch',
      label
    )
  }
}
