import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  type UpdateUserConsentsRequest,
  type User,
  type UserBrandConsent,
  type UserConsentsResponse,
  type UserDealerConsent,
  type UserGroupConsent,
  type ConsentPartnerDTO,
  UserService,
  type ConsentChannel
} from '@inside-hub-app/customer-portal-b2c-client'
import { MatomoIntegrationService, TransifexService } from '@inside-hub-app/customer-portal-shared'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged } from 'rxjs'
import { filter, isEmpty, isEqual, orderBy, toLower } from 'lodash'

@Component({
  selector: 'customer-portal-app-revolution-gdpr',
  templateUrl: './gdpr.component.html'
})
export class gdprComponent implements OnInit, OnDestroy {
  user: User

  consentsResponse: UserConsentsResponse
  disabled: boolean
  updates: UserBrandConsent[] | UserDealerConsent[] | UserGroupConsent[] = []

  locale

  sub = {
    onUserConsentsLoaded: null,
    onConsentDealersLoaded: null,
    onTransifexLanguageChange: null,
    consentDealerControlChanged: null,
    loadingConsents: null,
    loadingConsentDealers: null,
    onUserLoaded: null
  }

  loadingConsents: boolean
  loadingConsentDealers: boolean

  consentDealerControl = new FormControl()
  consentDealers: ConsentPartnerDTO[]
  filteredConsentDealers: ConsentPartnerDTO[]

  dropdownPartnerIdFound = false
  constructor (
    private readonly userService: UserService,
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    public readonly sharedService: SharedService,
    private readonly data: DataService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly matomoIntegrationService: MatomoIntegrationService
  ) {
  }

  get loading (): boolean {
    return this.loadingConsents || this.loadingConsentDealers
  }

  get dropdownPartnerId (): number {
    return this.consentDealerControl.getRawValue()?.partnerId
  }

  get consentsPartnerId (): number {
    return this.consentDealership?.partnerId
  }

  get hasConsentDealers (): boolean {
    return this.consentDealers?.length > 0
  }

  get consentDealership (): UserDealerConsent | UserGroupConsent {
    return isEmpty(this.consentsResponse?.dealers) ? this.consentsResponse?.group : this.consentsResponse?.dealers?.[0]
  }

  get showConsents (): boolean {
    return this.hasConsentDealers && this.dropdownPartnerIdFound && !isEmpty(this.consentDealership)
  }

  ngOnInit (): void {
    this.disabled = false
    this.initSubs()
  }

  initSubs (): void {
    this.sub.consentDealerControlChanged = this.consentDealerControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(isEqual)
      )
      .subscribe((value: string | ConsentPartnerDTO) => {
        if (typeof value === 'string') {
          this.filteredConsentDealers = this.filterDealers(value, this.consentDealers)
        } else {
          if (value.partnerId !== this.consentsPartnerId) {
            this.data.getConsents(value.partnerId)
          }
        }
      })

    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe((user: User) => {
      this.user = user
    })
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(locale => {
        if (!isEmpty(locale) && this.locale !== locale) {
          this.locale = locale
          this.data.getConsents(this.dropdownPartnerId)
        }
      })
    this.sub.onUserConsentsLoaded = this.data.onUserConsentsLoaded.subscribe(
      consents => {
        this.consentsResponse = this.sharedService.deepCopy(consents)
        this.updates = []
        this.disabled = false
        this.setConsentDealerControl()
      }
    )
    this.sub.onConsentDealersLoaded = this.data.onConsentDealersLoaded.subscribe(
      consentDealers => {
        this.consentDealers = this.sharedService.deepCopy(consentDealers)
        this.filteredConsentDealers = this.sharedService.deepCopy(consentDealers)
        this.setConsentDealerControl()
      }
    )
    this.sub.loadingConsents = this.data.loading.consents.subscribe(loading => {
      this.loadingConsents = loading
      this.cdRef.markForCheck()
    })
    this.sub.loadingConsentDealers = this.data.loading.consentDealers.subscribe(loading => {
      this.loadingConsentDealers = loading
      this.cdRef.markForCheck()
    })
  }

  setConsentDealerControl (): void {
    if (!this.hasConsentDealers || isEmpty(this.consentsResponse)) {
      return
    }
    const consentDealer = this.consentDealers.find(d => d.partnerId === this.consentsPartnerId)
    if (isEmpty(consentDealer)) {
      this.dropdownPartnerIdFound = false
    } else {
      this.dropdownPartnerIdFound = true
      if (!isEqual(this.consentDealerControl.value, consentDealer)) {
        this.consentDealerControl.setValue(consentDealer)
      }
    }
  }

  removeLegalTextLogo (obj): void {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.removeLegalTextLogo(obj[key])
      } else {
        if (key === 'legalText' || key === 'logo') {
          delete obj.legalText
          delete obj.logo
        }
      }
    })
  }

  updateConsents (): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Data protection change'
    )

    this.disabled = true
    const newConsents: UserConsentsResponse = {
      group: this.consentsResponse.group,
      dealers: this.consentsResponse.dealers,
      brands: this.consentsResponse.brands
    }

    const copy = this.sharedService.deepCopy(newConsents)
    this.removeLegalTextLogo(copy)

    const updateConsents: UpdateUserConsentsRequest = {
      url: window.location.href,
      userConsents: copy
    }
    this.userService.putConsents(updateConsents).subscribe(
      text => {
        this.logger.debug(text)
        this.disabled = false
        this.updates = []
        this.sharedService.openConfirmationPopup(
          'shared.success',
          'customerPortal.customer-portal.details.change.consents-changed'
        )
        this.data.getConsents(this.dropdownPartnerId)
      },
      error => {
        this.disabled = false
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'shared.general-error-message'
        )
        this.logger.error(error)
      }
    )
  }

  downloadPrintFile (preview?: boolean): void {
    this.sharedService.showSnackbar(
      'customerPortal.customer-portal.please-wait-loading-data',
      null,
      0
    )
    this.userService.getConsentDocument(this.consentsPartnerId).subscribe(
      response => {
        this.sharedService.closeSnackbar()
        const bytes = new Uint8Array(response)
        const file = new Blob([bytes], { type: 'application/pdf;base64' })
        const url = URL.createObjectURL(file)
        if (preview === true) {
          this.openBlob(url)
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          const name = (this.consentsResponse?.document?.id ?? 'consents') + '.pdf'
          a.download = name
          a.target = '_blank'
          a.click()
        }
      },
      () => {
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.loading-data.error',
          null
        )
      }
    )
  }

  openBlob (url: string): void {
    const newWindow = window.open()
    if (newWindow != null) {
      newWindow.location.href = url
    } else {
      this.sharedService.showSnackbar(
        'customerPortal.customer-portal.allow-popups',
        null
      )
    }
  }

  getConsentValue (consent, channel): boolean {
    if (channel === 'checked') {
      return consent?.consents?.checked === true
    } else {
      const val = consent?.consents?.channels?.find(s => s.name === channel)
      return val?.allow ?? false
    }
  }

  hasPhone (): boolean {
    const phones = this.sharedService.findUserPhone(this.user)
    return this.user?.isBusiness ? phones?.mobileBusiness != null : phones?.mobile != null
  }

  updateConsentValue (type: string, id: number, channel: string, event): void {
    if (type === 'dealer') {
      this.isValidConsents(id, this.consentsResponse.dealers, event, channel)
    } else if (type === 'brand') {
      this.isValidConsents(id, this.consentsResponse.brands, event, channel)
    } else {
      this.isValidConsents(id, this.consentsResponse.group, event, channel)
    }
  }

  errorMessage (type: ConsentChannel): void {
    switch (type) {
      case 'telephone':
      case 'sms':
      case 'whatsapp':
        if (!this.hasPhone()) {
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.no-phone'
          )
        }
        break
      case 'post':
        if (!this.addressExist()) {
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.no-address'
          )
        }
        break
    }
  }

  addressExist (): boolean {
    return this.user?.address != null
  }

  isValidConsents (id: number, element, event, channel: string): void {
    const el = Array.isArray(element) ? element.find(d => d.id === id) : element
    let consentValue

    if (channel === 'checked') {
      consentValue = el
      consentValue.consents.checked = this.sharedService.switchBoolean(consentValue.consents.checked)
    } else {
      consentValue = el.consents.channels.find(cd => cd.name === channel)
      consentValue.allow = event.checked
      consentValue.edit = this.sharedService.switchBoolean(consentValue.edit)
    }

    const index = this.updates.indexOf(consentValue)
    if (index > -1) {
      this.updates.splice(index, 1)
    } else {
      this.updates.push(consentValue)
    }
  }

  checkboxIsDisabled (consent: UserBrandConsent | UserDealerConsent | UserGroupConsent, channel: string): boolean {
    return (
      consent.consents.channels.find(s => s?.name === channel) == null ||
      (!this.hasPhone() && ['telephone', 'sms', 'whatsapp'].includes(channel)) ||
      (!this.addressExist() && channel === 'post')
    )
  }

  getTranslation (key: 'select-dealer'): string {
    switch (key) {
      case 'select-dealer':
        return this.transifexTranslationsService.translate(
          'Select dealer',
          {
            _key: 'customerPortal.customer-portal.gdpr.select-dealer',
            _tags: 'customer-portal, 3.21'
          }
        )
    }
  }

  formatDealerName (dealer: ConsentPartnerDTO): string {
    if (isEmpty(dealer)) return ''

    const partnerName = [dealer.partnerName?.trim(), dealer.partnerSubname?.trim()]
      .filter(Boolean)
      .join(' ')
      .trim()

    return [dealer.name?.trim(), partnerName]
      .filter(Boolean)
      .join(', ')
      .trim()
  }

  filterDealers (value: string, array: ConsentPartnerDTO[]): ConsentPartnerDTO[] {
    const filterValue = !isEmpty(value) ? toLower(value.trim()) : null

    return (filterValue != null)
      ? this.sortDealers(filter(array, dealer =>
        this.formatDealerName(dealer).toLowerCase().includes(filterValue)
      ))
      : array
  }

  sortDealers (value: ConsentPartnerDTO[]): ConsentPartnerDTO[] {
    return orderBy(value,
      [dealer => dealer.name ?? '', dealer => dealer.partnerName ?? '', dealer => dealer.partnerSubname ?? ''],
      ['asc', 'asc', 'asc']
    )
  }

  getPrimaryLegalText (): string {
    let legalText = this.consentsResponse?.legalText
    if (isEmpty(legalText)) {
      legalText = this.transifexTranslationsService.translate('Privacy notice', {
        _key: 'customerPortal.customer-portal.privacy.privacynotice',
        _tags: 'customer-portal, 3.1'
      })
    }
    return legalText
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
