import { Component, HostListener, type OnInit, type OnDestroy } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import {
  CptGoogleTagmanagerService,
  LoginService
} from '@inside-hub-app/customer-portal-shared'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { type TrackAndTraceVehicleDTOExtended, type VehicleDTOExtended } from '../../../../services/vehicles.service'
import { Router } from '@angular/router'

@Component({
  selector: 'customer-portal-app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  animations: [
    trigger('slideDown', [
      state('shown', style({ transform: 'translateY(0)', opacity: 1 })),
      state('hidden', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition('hidden => shown', animate('200ms ease-out')),
      transition('shown => hidden', animate('200ms ease-in'))
    ])
  ]
})
export class BottomNavbarComponent implements OnInit, OnDestroy {
  homepage
  lastScrollPosition = 0
  showNavbar = true

  @HostListener('window:scroll', ['$event'])
  onWindowScroll (event: any) {
    const currentScrollPosition = window.pageYOffset

    if (
      currentScrollPosition > this.lastScrollPosition &&
      currentScrollPosition > 50
    ) {
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }

    this.lastScrollPosition = currentScrollPosition
  }

  user: User
  loggedIn: boolean
  vehicles: VehicleDTOExtended[] = []
  inactiveVehicles: VehicleDTOExtended[] = []
  vehiclesTNT: TrackAndTraceVehicleDTOExtended[] = []
  firstVehicleVin: string
  sub = {
    onUserLoaded: null,
    combinedVehiclesSubscription: null
  }

  constructor (
    private readonly dataService: DataService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    public router: Router,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly loginService: LoginService
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
  }

  get hasVehicles (): boolean {
    return this.vehicles?.length > 0 || this.inactiveVehicles?.length > 0 || this.vehiclesTNT?.length > 0
  }

  get firstVehicle (): VehicleDTOExtended | TrackAndTraceVehicleDTOExtended {
    return this.vehicles?.[0] || this.vehiclesTNT?.[0] || this.inactiveVehicles?.[0]
  }

  logout (): void {
    this.loginService.logout(this.user)
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.sub.combinedVehiclesSubscription = this.dataService.combinedVehiclesLoaded
      .subscribe(([vehicles, inactiveVehicles, vehiclesTNT]) => {
        this.vehicles = vehicles
        this.inactiveVehicles = inactiveVehicles
        this.vehiclesTNT = vehiclesTNT?.filter(el => !(el.isHistory ?? false))
        if (this.firstVehicleVin == null && this.hasVehicles) {
          this.firstVehicleVin = 'commissionNumber' in this.firstVehicle
            ? `commissionNumber-${this.firstVehicle.commissionNumber}`
            : this.firstVehicle?.vin
        }
      },
      err => {
        if (err.status === 404) {
          this.vehicles = []
          this.inactiveVehicles = []
        }
      })
  }

  chatboxIsMinimized (): boolean {
    return this.sharedService.chatBotState?.state === 'minimized'
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
