<customer-portal-app-navbar></customer-portal-app-navbar>
<customer-portal-app-navbar-second></customer-portal-app-navbar-second>
<div class="bg-mat-ef-revolution-grey-500">
  <customer-portal-revolution-vehicle-list
    [mode]="'vehicles-view'"
    (statusChange)="onStatusChange($event)"
  ></customer-portal-revolution-vehicle-list>

  @if (selectedVehicleStatusValue === 'active') {
    <customer-portal-app-revolution-vehicle-navigation></customer-portal-app-revolution-vehicle-navigation>
  }

  <customer-portal-app-delete-vehicle
    [selectedVehicleStatusValue]="selectedVehicleStatusValue"
  ></customer-portal-app-delete-vehicle>
</div>
<customer-portal-footer-container></customer-portal-footer-container>
