<mat-menu class="w-screen max-w-none md:max-w-[288px] p-4 px-6" #menu="matMenu">
  <p
    class="font-efSans text-base font-bold text-mat-ef-revolution-primary-500 mb-4"
  >
    {{ 'my-account' | sharedTranslate }}
  </p>

  <div class="grid grid-cols-3 gap-4 md:flex md:flex-col md:gap-0">
    <p
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.summary.title',
          'White Box Link'
        )
      "
      [routerLink]="['/']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="home"></mat-icon>
      <T
        class="max-w-full"
        str="Overview"
        key="customerPortal.customer-portal.summary.title"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <p
      class="menu-column user-menu-column"
      (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      *ngIf="hasVehicles"
      [routerLink]="['/vehicles', firstVehicleVin ?? '', 'general']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="drive_eta"></mat-icon>
      {{ 'my-vehicles' | sharedTranslate }}
    </p>
    <p
      class="menu-column user-menu-column"
      (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      *ngIf="!hasVehicles"
      routerLink="/vehicles-not"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="car_off"></mat-icon>
      {{ 'my-vehicles' | sharedTranslate }}
    </p>
    <p
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.top-bar.appointments',
          'White Box Link'
        )
      "
      [routerLink]="['/appointments']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="event"></mat-icon>
      <T
        class="max-w-full"
        str="My dates"
        key="customerPortal.customer-portal.top-bar.appointments"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <div
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.top-bar.my-documents',
          'White Box Link'
        )
      "
      [routerLink]="['/my-documents']"
      routerLinkActive="active"
    >
      <div class="w-10 h-10 relative flex md:hidden">
        <customer-portal-app-unread-document
          class="absolute -right-1"
        ></customer-portal-app-unread-document>
        <mat-icon class="w-10 h-10" svgIcon="library_books"></mat-icon>
      </div>

      <div class="fit-content relative">
        <customer-portal-app-unread-document
          class="absolute -right-1 translate-x-full hidden md:flex"
        ></customer-portal-app-unread-document>
        <T
          class="max-w-full"
          str="Customer History"
          key="customerPortal.customer-portal.top-bar.my-documents"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </div>
    <p
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.top-bar.additional-services',
          'White Box Link'
        )
      "
      [routerLink]="['/services']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="business"></mat-icon>
      <T
        class="max-w-full"
        str="Services"
        key="customerPortal.customer-portal.top-bar.additional-services"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <p
      *ngIf="hasMarketplace"
      class="menu-column user-menu-column"
      (click)="sendGTMNavigationData('shared.my-marketplace', 'White Box Link')"
      [routerLink]="[navigationMarketplace?.[0]?.path]"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="offer"></mat-icon>
      {{ 'my-marketplace' | sharedTranslate }}
    </p>
    <div
      *ngIf="hasMyMailbox"
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.my-mailbox',
          'White Box Link'
        )
      "
      [routerLink]="['/my-mailbox']"
      routerLinkActive="active"
    >
      <div class="w-10 h-10 relative flex md:hidden">
        <customer-portal-app-unread-mail
          class="absolute -right-1"
        ></customer-portal-app-unread-mail>
        <mat-icon class="w-10 h-10" svgIcon="mail"></mat-icon>
      </div>

      <div class="fit-content relative">
        <customer-portal-app-unread-mail
          class="absolute -right-1 translate-x-full hidden md:flex"
        ></customer-portal-app-unread-mail>
        <T
          class="max-w-full"
          str="My mail box"
          key="customerPortal.customer-portal.my-mailbox"
          tags="customer-portal, 3.1"
        >
        </T>
      </div>
    </div>
    <p
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.my-profile',
          'White Box Link'
        )
      "
      [routerLink]="['/user', 'personal-data']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="account_circle"></mat-icon>
      <T
        class="max-w-full"
        str="My profile"
        key="customerPortal.customer-portal.my-profile"
        tags="customer-portal, 3.1"
      ></T>
    </p>

    <p
      *ngIf="screenSize === 'mobile'"
      class="menu-column user-menu-column"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.about',
          'White Box Link'
        )
      "
      [routerLink]="['/about']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="info"></mat-icon>
      <T
        class="max-w-full"
        str="About"
        key="customerPortal.customer-portal.about"
        tags="customer-portal, 3.9"
      ></T>
    </p>

    <p
      *ngIf="hasTestView"
      class="menu-column user-menu-column"
      [routerLink]="['/test']"
      routerLinkActive="active"
    >
      <mat-icon class="w-10 h-10 md:hidden" svgIcon="build"></mat-icon>
      <T
        class="max-w-full"
        str="Test"
        key="customerPortal.customer-portal.test"
        tags="customer-portal, 3.3"
      ></T>
    </p>
  </div>

  <mat-divider class="relative w-full my-4"></mat-divider>
  <div class="grid grid-cols-3 gap-4 md:flex md:flex-col md:gap-0">
    <p
      data-cy="user-menu-logout"
      class="menu-column user-menu-column col-start-2 md:flex-row md:gap-1"
      *ngIf="loggedIn"
      (click)="
        logout();
        sendGTMNavigationData(
          'customerPortal.customer-portal.top-bar.logout',
          'White Box Link'
        )
      "
      routerLinkActive="active"
    >
      <mat-icon
        class="w-10 h-10 md:w-6 md:h-6"
        svgIcon="exit_to_app"
      ></mat-icon>
      <T
        class="max-w-full"
        str="Log off"
        key="customerPortal.customer-portal.top-bar.logout"
        tags="customer-portal, 3.1"
      ></T>
    </p>
  </div>
</mat-menu>
