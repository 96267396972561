/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core'
import {
  type User,
  type UserConsentsResponse,
  type ConsentPartnerDTO,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGeolocationService,
  InsuranceService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, type Observable, Subject, combineLatest, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { type UpcomingAppointmentDTO } from './appointments.service'
import { type ArticleResponse } from './dash-eco.service'
import { GalleryService, type VehicleGalleryDTO } from './gallery.service'
import { type HeaderResponse } from './header-footer-service.service'
import { LocalStorageService } from './local-storage.service'
import { type PersonalizeDTO } from './personalization.service'
import { type RecallDTO, RecallsService } from './recalls.service'
import { type ServicePackageDTO } from './service-package.service'
import { SharedService } from './shared.service'
import {
  type TiresAppointmentDTO,
  TiresService,
  type TiresStockDTO
} from './tires.service'
import {
  type BasicDocumentDTOExtended,
  VehicleDocumentsService,
  type VehiclePromotionDocumentDTOExtended
} from './vehicle-documents.service'
import { type VehicleNotificationsDTO } from './vehicle-notifications.service'
import { VehicleRemindersService } from './vehicle-reminders.service'
import {
  type FuelPriceDTO,
  type TrackAndTraceVehicleDTOExtended,
  type VehicleDTOExtended,
  type VehicleDealersDTO,
  type VehicleFrequentDriverDTO,
  VehiclesService
} from './vehicles.service'
import { type Warranty, WarrantyService } from './warranty.service'
import { format } from 'date-fns'

@Injectable({
  providedIn: 'root'
})
export class DataService {
  user
  location
  constructor (
    private readonly vehiclesService: VehiclesService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly galleryService: GalleryService,
    private readonly tiresService: TiresService,
    private readonly logger: NGXLogger,
    private readonly warrantyService: WarrantyService,
    private readonly recallsService: RecallsService,
    private readonly localStorage: LocalStorageService,
    private readonly sharedService: SharedService,
    private readonly userService: UserService,
    private readonly vehicleRemindersService: VehicleRemindersService,
    private readonly cptGeolocationService: CptGeolocationService,
    private readonly insuranceService: InsuranceService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasTracking = this.remoteConfigService.get('hasTracking')
    this.hasPromotions = this.remoteConfigService.get('hasPromotions')
    this.onUserLoaded.subscribe(user => {
      this.user = user != null ? user : null
    })

    this.cptGeolocationService.onGeolocationChanged.subscribe(location => {
      this.location = location
    })

    // reload documents after mark all as read
    this.vehicleDocumentsService.onReloadAfterMarkAsRead.subscribe(vin => {
      // always get all documents and invoices to update unread count
      void this.getInvoices()
      void this.getMyDocuments()
      if (this.sharedService.stringExists(vin)) {
        this.getVehicleDocuments(vin, true)
      }
    })

    // for my documents view unread count since its visible outside my documents view
    combineLatest([
      this.onMyDocumentsLoaded,
      this.onInvoicesLoaded
    ]).subscribe(res => {
      const allDocuments = (res?.[0] ?? []).concat(res?.[1] ?? [])

      // update unread count
      const unreadDocumentsCount = this.vehicleDocumentsService.unreadDocumentsCount(allDocuments)
      this.vehicleDocumentsService.unreadMyDocuments(unreadDocumentsCount)
    })

    // all vehicles loaded
    const vehiclesTNT$ = this.hasTracking ? this.onUserTrackingLoaded : of([])
    this.combinedVehiclesLoaded = combineLatest([this.onUserVehiclesLoaded, this.onInactiveVehiclesLoaded, vehiclesTNT$])
  }

  private readonly hasPromotions
  private readonly hasTracking

  // Loading indicators for various backend calls
  public loading = {
    vehicleNotifications: new BehaviorSubject<boolean>(true),
    dealers: new BehaviorSubject<boolean>(true),
    documents: new BehaviorSubject<boolean>(true),
    galleries: new BehaviorSubject<boolean>(true),
    serviceHistory: new BehaviorSubject<boolean>(true),
    servicePackage: new BehaviorSubject<boolean>(true),
    warranty: new BehaviorSubject<boolean>(true),
    recalls: new BehaviorSubject<boolean>(true),
    tires: new BehaviorSubject<boolean>(true),
    vehicleDetails: new BehaviorSubject<boolean>(true),
    tireAppointments: new BehaviorSubject<boolean>(true),
    frequentDriver: new BehaviorSubject<boolean>(true),
    dashEcoArticles: new BehaviorSubject<boolean>(true),
    promotions: new BehaviorSubject<boolean>(true),
    userDealers: new BehaviorSubject<boolean>(true),
    upcomingAppointments: new BehaviorSubject<boolean>(true),
    fuelPrices: new BehaviorSubject<boolean>(true),
    consents: new BehaviorSubject<boolean>(true),
    consentDealers: new BehaviorSubject<boolean>(true),
    invoices: new BehaviorSubject<boolean>(true),
    myDocuments: new BehaviorSubject<boolean>(true)
  }

  loadVehicleDataSub = {
    getVehicleDealers: null,
    getServicePackage: null,
    getWarranty: null,
    getRecallsForVehicle: null,
    getVehicleDetails: null,
    getDocuments: null,
    getGalleries: null,
    getTires: null,
    tireAppointmentLink: null,
    getFrequentDriver: null,
    getPromotions: null,
    getWarrantySP: null,
    getVehicleReminders: null,
    getFuelPrice: null,
    getInsurance: null
  }

  // Last route
  public lastRoute = ''

  // vehicleNotifications
  private readonly _vehicleNotifications =
    new BehaviorSubject<VehicleNotificationsDTO>(null)

  onVehicleNotificationsLoaded = this._vehicleNotifications
    .asObservable()
    .pipe(filter(this.isNonNull))

  // Language changed
  private readonly _language = new BehaviorSubject<string>(null)
  onLanguageChange = this._language.asObservable().pipe(filter(this.isNonNull))

  // change language started
  private readonly _changeLanguageStarted = new Subject<void>()
  onChangeLanguageStarted = this._changeLanguageStarted.asObservable()

  // initial Language setup
  private readonly _initialLanguage = new BehaviorSubject<string>(null)
  onInitialLanguageSetup = this._initialLanguage
    .asObservable()
    .pipe(filter(this.isNonNull))

  // Dealer changed
  private readonly _dealers = new BehaviorSubject<VehicleDealersDTO>(null)
  onDealerChange = this._dealers.asObservable().pipe(filter(this.isNonNull))

  // Vehicle dealers
  private readonly _vehicleDealers = new BehaviorSubject<VehicleDealersDTO>(
    null
  )

  onVehicleDealersLoaded = this._vehicleDealers.asObservable()

  // User dealers
  private readonly _userDealers = new BehaviorSubject<VehicleDealersDTO[]>(null)
  onUserDealersLoaded = this._userDealers
    .asObservable()
    .pipe(filter(this.isNonNull))

  // reload User dealers
  private readonly _reloadUserDealers = new Subject<boolean>()
  onReloadUserDealers = this._reloadUserDealers.asObservable()

  // Vehicles that needs attention (because of leasing or warranty expiry)
  private readonly _vehicleNeedsAttention = new BehaviorSubject<
  VehicleDTOExtended[]
  >([])

  onVehicleNeedsAttention = this._vehicleNeedsAttention
    .asObservable()
    .pipe(filter(this.isNonNull))

  // Vehicle documents
  private readonly _vehicleDocuments = new BehaviorSubject<
  BasicDocumentDTOExtended[]
  >([])

  onVehicleDocumentsLoaded = this._vehicleDocuments
    .asObservable()
    .pipe(filter(this.isNonNull))

  // mydocuments
  private readonly _myDocuments = new BehaviorSubject<
  BasicDocumentDTOExtended[]
  >([])

  onMyDocumentsLoaded = this._myDocuments
    .asObservable()

  // invoices
  private readonly _invoices = new BehaviorSubject<
  BasicDocumentDTOExtended[]
  >([])

  onInvoicesLoaded = this._invoices
    .asObservable()

  // Gallery
  private readonly _galleries = new BehaviorSubject<VehicleGalleryDTO[]>([])
  onGalleriesLoaded = this._galleries.asObservable()

  // Vehicle promotions
  private readonly _vehiclePromotions = new BehaviorSubject<
  VehiclePromotionDocumentDTOExtended[]
  >([])

  onVehiclePromotionsLoaded = this._vehiclePromotions.asObservable()

  // Vehicle details
  private readonly _vehicleDetails = new BehaviorSubject<VehicleDTOExtended>(
    null
  )

  onVehicleDetailsLoaded = this._vehicleDetails.asObservable()

  // Vehicle frequent driver
  private readonly _vehicleFrequentDriver =
    new BehaviorSubject<VehicleFrequentDriverDTO>(null)

  onVehicleFrequentDriverLoaded = this._vehicleFrequentDriver.asObservable()

  // Vehicle tires
  private readonly _vehicleTires = new BehaviorSubject<TiresStockDTO>(null)
  onVehicleTiresLoaded = this._vehicleTires.asObservable()

  // Vehicle tire appointment link
  private readonly _vehicleTireAppointmentLink =
    new BehaviorSubject<TiresAppointmentDTO>(null)

  onVehicleTiresAppintmentLinkLoaded =
    this._vehicleTireAppointmentLink.asObservable()

  // Vehicle service package
  private readonly _vehicleServicePackage = new BehaviorSubject<
  ServicePackageDTO[]
  >(null)

  onVehicleServicePackageLoaded = this._vehicleServicePackage.asObservable()

  // Vehicle warranty
  private readonly _vehicleWarranty = new BehaviorSubject<Warranty>(null)
  onVehicleWarrantyLoaded = this._vehicleWarranty.asObservable()

  // Vehicle recalls
  private readonly _vehicleRecalls = new BehaviorSubject<RecallDTO>(null)

  onVehicleRecallsLoaded = this._vehicleRecalls.asObservable()

  // Active vehicle VIN
  private readonly _vehicleVIN = new BehaviorSubject<string>(null)
  onVehicleVinChange = this._vehicleVIN
    .asObservable()
    .pipe(filter(this.isNonNull))

  // User
  private readonly _user = new BehaviorSubject<User>(null)
  onUserLoaded = this._user.asObservable().pipe(filter(this.isNonNull))

  // User vehicles
  private readonly _userVehicles = new BehaviorSubject<VehicleDTOExtended[]>(
    null
  )

  onUserVehiclesLoaded = this._userVehicles
    .asObservable()
    .pipe(filter(this.isNonNull))

  // Inactive vehicles
  private readonly _inactiveVehicles = new BehaviorSubject<VehicleDTOExtended[]>(
    null
  )

  onInactiveVehiclesLoaded = this._inactiveVehicles
    .asObservable()
    .pipe(filter(this.isNonNull))

  // all vehicles
  combinedVehiclesLoaded: Observable<[VehicleDTOExtended[], VehicleDTOExtended[], TrackAndTraceVehicleDTOExtended[]]>

  private readonly _userConsents = new BehaviorSubject<UserConsentsResponse>(
    null
  )

  onUserConsentsLoaded = this._userConsents
    .asObservable()
    .pipe(filter(this.isNonNull))

  private readonly _consentDealers = new BehaviorSubject<ConsentPartnerDTO[]>(
    null
  )

  onConsentDealersLoaded = this._consentDealers
    .asObservable()

  // Phone number change
  private readonly _phoneNumber = new BehaviorSubject<string>(null)
  onPhoneNumberVerified = this._phoneNumber
    .asObservable()
    .pipe(filter(this.isNonNull))

  // Appointments
  private readonly _upcomingAppointments = new BehaviorSubject<
  UpcomingAppointmentDTO[]
  >(null)

  onUpcomingAppointmentsLoaded = this._upcomingAppointments
    .asObservable()
    .pipe(filter(this.isNonNull))

  // First appointment
  private readonly _firstUpcomingAppointment =
    new BehaviorSubject<UpcomingAppointmentDTO>(null)

  onFirstUpcomingAppointmentLoaded =
    this._firstUpcomingAppointment.asObservable()

  // Refresh vehicle data
  private readonly _refreshVehicleData =
    new BehaviorSubject<VehicleDTOExtended>(null)

  onRefreshVehicleData = this._refreshVehicleData
    .asObservable()
    .pipe(filter(this.isNonNull))

  // hide user notice
  private readonly _hideUserNotice = new BehaviorSubject<boolean>(false)
  onHideUserNotice = this._hideUserNotice.asObservable()

  // Dash eco articles
  private readonly _dashEcoArticles = new BehaviorSubject<ArticleResponse>(null)
  onDashEcoArticlesLoaded = this._dashEcoArticles.asObservable()

  // Dash eco articles from storage
  private readonly _dashEcoArticlesFromLocalStorage =
    new BehaviorSubject<ArticleResponse>(null)

  onDashEcoArticlesFromLocalStorageLoaded =
    this._dashEcoArticlesFromLocalStorage.asObservable()

  // header Footer data changed
  private readonly _headerFooterData = new BehaviorSubject<HeaderResponse>(null)
  onHeaderFooterDataLoaded = this._headerFooterData.asObservable()

  // personalization
  private readonly _personalization = new BehaviorSubject<PersonalizeDTO>(null)

  onPersonalizationLoaded = this._personalization.asObservable()

  // User track and trace
  private readonly _userVehiclesTNT = new BehaviorSubject<
  TrackAndTraceVehicleDTOExtended[]
  >(null)

  onUserTrackingLoaded = this._userVehiclesTNT
    .asObservable()
    .pipe(filter(this.isNonNull))

  // new appointment is added
  private readonly _newAppointmentAdded = new Subject<boolean>()
  onNewAppointmentAdded = this._newAppointmentAdded.asObservable()

  // Vehicle fuel prices
  private readonly _vehicleFuelPrices = new BehaviorSubject<FuelPriceDTO[]>(
    null
  )

  onVehicleFuelPricesLoaded = this._vehicleFuelPrices.asObservable()

  userVehiclesTNTLoaded (vehicles: TrackAndTraceVehicleDTOExtended[]): void {
    this._userVehiclesTNT.next(vehicles)
  }

  // Language changed
  languageChanged (lang: string): void {
    this._language.next(lang)
  }

  changeLanguageStarted (): void {
    this._changeLanguageStarted.next()
  }

  // Dealer changed
  dealerChanged (dealers: VehicleDealersDTO): void {
    this._dealers.next(dealers)
  }

  // Dealers loaded
  vehicleDealersLoaded (dealers: VehicleDealersDTO): void {
    this._vehicleDealers.next(dealers)
  }

  // User Dealers loaded
  userDealersLoaded (dealers: VehicleDealersDTO[]): void {
    this._userDealers.next(dealers)
  }

  reloadUserDealers (): void {
    this._reloadUserDealers.next(true)
  }

  // Vehicles that need attention
  setVehiclesNeedAttention (vehicles: VehicleDTOExtended[]): void {
    this._vehicleNeedsAttention.next(vehicles)
  }

  // Add vehicle to vehicles that need attention
  addVehicleNeedsAttention (v: VehicleDTOExtended): void {
    const vehicles = this._vehicleNeedsAttention.getValue()
    vehicles.push(v)
    this._vehicleNeedsAttention.next(vehicles)
  }

  vehicleVinChanged (vin: string): void {
    if (vin != null && vin !== this._vehicleVIN.value) {
      this._vehicleVIN.next(vin)
    }
  }

  vehicleDocumentsLoaded (documents: BasicDocumentDTOExtended[]): void {
    this._vehicleDocuments.next(documents)
  }

  invoicesLoaded (invoices: BasicDocumentDTOExtended[]): void {
    this._invoices.next(invoices)
  }

  myDocumentsLoaded (documents: BasicDocumentDTOExtended[]): void {
    this._myDocuments.next(documents)
  }

  galleriesLoaded (documents: VehicleGalleryDTO[]): void {
    this._galleries.next(documents)
  }

  vehiclePromotionsLoaded (
    promotions: VehiclePromotionDocumentDTOExtended[]
  ): void {
    this._vehiclePromotions.next(promotions)
  }

  vehicleDetailsLoaded (vehicleDetails: VehicleDTOExtended): void {
    this._vehicleDetails.next(vehicleDetails)
  }

  vehicleFrequentDriverLoaded (
    vehicleFrequentDriver: VehicleFrequentDriverDTO
  ): void {
    this._vehicleFrequentDriver.next(vehicleFrequentDriver)
  }

  vehicleTiresLoaded (vehicleTires: TiresStockDTO): void {
    this._vehicleTires.next(vehicleTires)
  }

  /**
   *
   * now it covers all services, not only tires
   */
  vehicleTiresAppintmentLinkLoaded (link: TiresAppointmentDTO): void {
    this._vehicleTireAppointmentLink.next(link)
  }

  vehicleServicePackageLoaded (p: ServicePackageDTO[]): void {
    this._vehicleServicePackage.next(p)
  }

  vehicleWarrantyLoaded (w: Warranty): void {
    this._vehicleWarranty.next(w)
  }

  vehicleRecallsLoaded (r: RecallDTO): void {
    this._vehicleRecalls.next(r)
  }

  hideUserNotice (hide: boolean): void {
    this._hideUserNotice.next(hide)
  }

  userVehiclesLoaded (vehicles: VehicleDTOExtended[]): void {
    this._userVehicles.next(vehicles)
  }

  inactiveVehiclesLoaded (vehicles: VehicleDTOExtended[]): void {
    this._inactiveVehicles.next(vehicles)
  }

  userConsentsLoaded (consents: UserConsentsResponse): void {
    this._userConsents.next(consents)
  }

  consentDealersLoaded (consentDealers: ConsentPartnerDTO[]): void {
    this._consentDealers.next(consentDealers)
  }

  removeVehicle (vin: string, addToInactive = false): void {
    let vehicles = this._userVehicles.getValue()
    const removedVehicle = vehicles.find(vehicle => vehicle.vin === vin)

    vehicles = vehicles.filter(vehicle => vehicle.vin !== vin)
    this._userVehicles.next(vehicles)

    if (addToInactive && removedVehicle) {
      removedVehicle.deactivateRequestDate = format(new Date(), 'yyyy-MM-dd').toString()
      removedVehicle.active = false
      removedVehicle.requestedForRemoval = true
      const inactiveVehicles = this._inactiveVehicles.getValue()
      this._inactiveVehicles.next([...inactiveVehicles, removedVehicle])
    }
  }

  vehicleNotificationsLoaded (n: VehicleNotificationsDTO): void {
    this._vehicleNotifications.next(n)
  }

  phoneNumberVerified (phoneNumber: string): void {
    this._phoneNumber.next(phoneNumber)
  }

  upcomingAppointmentsLoaded (appointments: UpcomingAppointmentDTO[]): void {
    this._upcomingAppointments.next(appointments)
  }

  firstUpcomingAppointmentLoaded (appointment: UpcomingAppointmentDTO): void {
    this._firstUpcomingAppointment.next(appointment)
  }

  refreshVehicleData (vehicle: VehicleDTOExtended): void {
    this._refreshVehicleData.next(vehicle)
  }

  userLoaded (user: User): void {
    this._user.next(user)
  }

  dashEcoArticlesLoaded (articles: ArticleResponse): void {
    this._dashEcoArticles.next(articles)
  }

  dashEcoArticlesFromLocalStorageLoaded (articles: ArticleResponse): void {
    this._dashEcoArticlesFromLocalStorage.next(articles)
  }

  initialLanguageSetup (lang: string): void {
    this._initialLanguage.next(lang)
  }

  headerFooterDataLoaded (headerFooterData: HeaderResponse): void {
    this._headerFooterData.next(headerFooterData)
  }

  personalizationLoaded (personalization: PersonalizeDTO): void {
    this._personalization.next(personalization)
  }

  newAppointmentAdded (): void {
    this._newAppointmentAdded.next(true)
  }

  // fuel prices loaded
  vehicleFuelPricesLoaded (fuelPrices: FuelPriceDTO[]): void {
    this._vehicleFuelPrices.next(fuelPrices)
  }

  // Don't emit null values from Observables
  isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null
  }

  refreshVehicleDetails (vin: string, includeImages: boolean): void {
    this.loading.vehicleDetails.next(true)
    const lang = this.sharedService.currentLanguage()
    this.vehiclesService.getVehicleDetails(vin, includeImages, lang).subscribe(
      vehicleDetails => {
        this.vehicleDetailsLoaded(vehicleDetails)
        this.loading.vehicleDetails.next(false)
        void this.localStorage.setVehicleDetails(vin, vehicleDetails)
      },
      error => {
        this.logger.log(error)
        this.vehicleDetailsLoaded(null)
        this.loading.vehicleDetails.next(false)
        void this.localStorage.setVehicleDetails(vin, null)
      }
    )
  }

  hasFuelPriceComparison (vehicle): boolean {
    const fuelTypeArray = ['LPG', 'diesel', 'petrol', 'CNG', 'LNG']
    if (
      Boolean(this.remoteConfigService.get('hasFuelPriceComparison')) &&
      vehicle?.fuelType != null &&
      fuelTypeArray.includes(vehicle.fuelType) &&
      (this.location?.coords?.latitude != null ||
        this.user?.address?.[0]?.zip != null)
    ) {
      return true
    } else {
      return false
    }
  }

  /** Load all single vehicle data */
  async loadVehicleData (
    vehicle: VehicleDTOExtended,
    preventLoading?: boolean
  ): Promise<void> {
    if (vehicle != null) {
      this.unsubPreviousloadVehicleData()
      const vin = vehicle.vin
      const vehicleId = vehicle.id
      const lang = this.sharedService.currentLanguage()
      let checkIfEqual = true

      let vehicleData
      try {
        vehicleData = (await this.localStorage.getVehicleByVin(
          vehicle.vin
        )) as VehicleDTOExtended
      } catch (error) {
        this.logger.debug(error)
      }
      // if the vehicle doesnt have anything in relations - dont look for diff
      if (
        !(vehicleData != null && Object.keys(vehicleData.relations).length > 0)
      ) {
        checkIfEqual = false
      }

      if (preventLoading !== true) {
        this.setLoader(true)
      }

      // fuel prices
      if (this.hasFuelPriceComparison(vehicle)) {
        this.vehicleFuelPricesLoaded(null)
        this.loadVehicleDataSub.getFuelPrice = this.vehiclesService
          .getFuelPrices(
            vehicle.fuelType,
            this.user?.address?.[0]?.zip,
            this.location?.coords
          )
          .subscribe(
            prices => {
              this.vehicleFuelPricesLoaded(prices)
              this.loading.fuelPrices.next(false)
            },
            error => {
              this.logger.log(error)
              this.loading.fuelPrices.next(false)
              this.vehicleFuelPricesLoaded(null)
            }
          )
      } else {
        this.loading.fuelPrices.next(false)
      }

      // Frequent driver
      if (vehicleId != null) {
        this.vehicleFrequentDriverLoaded(null)
        this.loadVehicleDataSub.getFrequentDriver = this.vehiclesService
          .getFrequentDriver(
            vehicleId,
            vehicle.business === true ? vehicle.ownershipId : null
          )
          .subscribe(
            frequentDriver => {
              let fqd = null
              if (this.sharedService.objectHasKeys(frequentDriver)) {
                fqd = frequentDriver
              }
              this.vehicleFrequentDriverLoaded(fqd)
              this.loading.frequentDriver.next(false)
              void this.localStorage.setFrequentDriver(vin, fqd, checkIfEqual)
            },
            error => {
              this.logger.log(error)
              this.vehicleFrequentDriverLoaded(null)
              this.loading.frequentDriver.next(false)
              void this.localStorage.setFrequentDriver(vin, null, checkIfEqual)
            }
          )
      } else {
        this.loading.frequentDriver.next(false)
      }

      // Vehicle details
      this.vehicleDetailsLoaded(null)
      this.loadVehicleDataSub.getVehicleDetails = this.vehiclesService
        .getVehicleDetails(vin, false, lang)
        .subscribe(
          vehicleDetails => {
            this.vehicleDetailsLoaded(vehicleDetails)
            this.loading.vehicleDetails.next(false)
            void this.localStorage.setVehicleDetails(
              vin,
              vehicleDetails,
              checkIfEqual
            )
          },
          error => {
            this.logger.log(error)
            this.vehicleDetailsLoaded(null)
            this.loading.vehicleDetails.next(false)
            void this.localStorage.setVehicleDetails(vin, null, checkIfEqual)
          }
        )

      // Dealers
      this.vehicleDealersLoaded(null)
      this.loadVehicleDataSub.getVehicleDealers = this.vehiclesService
        .getVehicleDealers(vin, true, true, true)
        .subscribe(
          dealers => {
            this.vehicleDealersLoaded(dealers)
            this.loading.dealers.next(false)
            void this.localStorage.setVehicleDealers(vin, dealers, checkIfEqual)
          },
          error => {
            this.logger.log(error)
            // empty dealers if we get 404
            let dealers = null
            if (error?.error?.code === '404' || error?.status === 404) {
              dealers = {
                vin
              }
            }
            this.loading.dealers.next(false)
            this.vehicleDealersLoaded(dealers)
            void this.localStorage.setVehicleDealers(vin, dealers, checkIfEqual)
          }
        )

      // Vehicle promotions
      if (this.hasPromotions) {
        this.vehiclePromotionsLoaded(null)
        this.loadVehicleDataSub.getPromotions = this.vehicleDocumentsService
          .getVehiclePromotions(vin, lang)
          .subscribe(
            promotions => {
              this.vehiclePromotionsLoaded(promotions)
              this.loading.promotions.next(false)
              void this.localStorage.setPromotions(
                vin,
                promotions,
                checkIfEqual
              )
            },
            error => {
              this.logger.log(error)
              this.vehiclePromotionsLoaded(null)
              this.loading.promotions.next(false)
              void this.localStorage.setPromotions(vin, null, checkIfEqual)
            }
          )
      } else {
        this.loading.promotions.next(false)
      }

      // Warranty Service Packages
      if (
        Boolean(this.remoteConfigService.get('features.servicePackage')) ||
        Boolean(this.remoteConfigService.get('features.warranty'))
      ) {
        this.warrantySPLoaded(null, null)
        if (vehicle.createdByDataSource === 'customer_portal') {
          this.warrantySPLoading(false)
        } else {
          this.loadVehicleDataSub.getWarrantySP = this.warrantyService
            .getWarrantySP(vin, lang)
            .subscribe(
              warrantySP => {
                this.warrantySPLoaded(
                  warrantySP?.servicePackages != null
                    ? warrantySP.servicePackages
                    : null,
                  warrantySP?.warranty != null ? warrantySP.warranty : null
                )
                this.warrantySPLoading(false)
                void this.localStorage.setServicePackage(
                  vin,
                  warrantySP?.servicePackages != null
                    ? warrantySP.servicePackages
                    : null,
                  checkIfEqual
                )
                void this.localStorage.setWarranty(
                  vin,
                  warrantySP?.warranty != null ? warrantySP.warranty : null,
                  checkIfEqual
                )
              },
              error => {
                this.logger.log(error)
                this.warrantySPLoading(false)
                this.warrantySPLoaded(null, null)
                void this.localStorage.setServicePackage(
                  vin,
                  null,
                  checkIfEqual
                )
                void this.localStorage.setWarranty(vin, null, checkIfEqual)
              }
            )
        }
      } else {
        this.warrantySPLoading(false)
      }

      // Insurance
      if (
        this.remoteConfigService.get('hasInsurance') === true
      ) {
        this.insuranceService.insuranceLoaded(null)
        this.loadVehicleDataSub.getInsurance = this.insuranceService
          .getInsurance(vin)
          .subscribe(
            insurance => {
              this.insuranceService.insuranceLoaded(
                insurance != null
                  ? insurance
                  : null
              )
              this.insuranceService.loadingInsurance.next(false)
              void this.localStorage.setInsurance(
                vin,
                insurance != null
                  ? insurance
                  : null,
                checkIfEqual
              )
            },
            error => {
              this.logger.log(error)
              this.insuranceService.loadingInsurance.next(false)
              this.insuranceService.insuranceLoaded(null)
              void this.localStorage.setInsurance(
                vin,
                null,
                checkIfEqual
              )
            }
          )
      } else {
        this.insuranceService.loadingInsurance.next(false)
      }

      // Vehicle documents
      this.getVehicleDocuments(vin, checkIfEqual)

      // Gallery
      this.galleriesLoaded(null)
      this.loadVehicleDataSub.getGalleries = this.galleryService
        .getGalleries(vin)
        .subscribe(
          galleries => {
            this.galleriesLoaded(galleries)
            this.loading.galleries.next(false)
            void this.localStorage.setGalleries(vin, galleries, checkIfEqual)
          },
          error => {
            this.logger.log(error)
            this.galleriesLoaded(null)
            this.loading.galleries.next(false)
            void this.localStorage.setGalleries(vin, null, checkIfEqual)
          }
        )

      // Service manager appointments link - now for all services, not just tires
      this.vehicleTiresAppintmentLinkLoaded(null)
      if (this.remoteConfigService.get('hasServiceManager') !== true ||
        vehicle.createdByDataSource === 'customer_portal') {
        this.loading.tireAppointments.next(false)
      } else {
        this.loadVehicleDataSub.tireAppointmentLink = this.tiresService
          .tireAppointmentLink(vin)
          .subscribe(
            tireAppointment => {
              let tire = null
              if (this.sharedService.objectHasKeys(tireAppointment)) {
                tire = tireAppointment
              }
              this.vehicleTiresAppintmentLinkLoaded(tire)
              this.loading.tireAppointments.next(false)
              void this.localStorage.setTiresAppintment(vin, tire, checkIfEqual)
            },
            error => {
              this.logger.log(error)
              this.vehicleTiresAppintmentLinkLoaded(null)
              this.loading.tireAppointments.next(false)
              void this.localStorage.setTiresAppintment(vin, null, checkIfEqual)
            }
          )
      }

      // Vehicle tires
      this.vehicleTiresLoaded(null)
      if (vehicle.createdByDataSource === 'customer_portal') {
        this.loading.tires.next(false)
      } else {
        this.loadVehicleDataSub.getTires = this.tiresService
          .getTires(vin)
          .subscribe(
            tires => {
              this.vehicleTiresLoaded(tires)
              this.loading.tires.next(false)
              void this.localStorage.setTires(vin, tires, checkIfEqual)
            },
            error => {
              this.logger.log(error)
              this.vehicleTiresLoaded(null)
              this.loading.tires.next(false)
              void this.localStorage.setTires(vin, null, checkIfEqual)
            }
          )
      }

      // Recalls
      if (this.remoteConfigService.get('features.recalls')) {
        this.vehicleRecallsLoaded(null)
        if (vehicle.createdByDataSource === 'customer_portal') {
          this.loading.recalls.next(false)
        } else {
          const language = this.sharedService.currentLanguage()
          this.loadVehicleDataSub.getRecallsForVehicle = this.recallsService
            .getRecallsForVehicle(vin, language)
            .subscribe(
              recalls => {
                this.vehicleRecallsLoaded(recalls)
                this.loading.recalls.next(false)
                void this.localStorage.setRecalls(vin, recalls, checkIfEqual)
              },
              error => {
                this.logger.log(error)
                this.loading.recalls.next(false)
                this.vehicleRecallsLoaded(null)
                void this.localStorage.setRecalls(vin, null, checkIfEqual)
              }
            )
        }
      } else {
        this.loading.recalls.next(false)
      }

      // Reminders
      if (this.sharedService.showRemindersForVehicle(vehicle)) {
        this.vehicleRemindersService.vehicleRemindersLoaded(null)
        this.loadVehicleDataSub.getVehicleReminders =
          this.vehicleRemindersService.getVehicleReminders(vin).subscribe(
            vehicleReminders => {
              this.vehicleRemindersService.vehicleRemindersLoaded(
                vehicleReminders
              )
              this.vehicleRemindersService.loading.vehicleReminders.next(false)
              void this.localStorage.setReminders(
                vin,
                vehicleReminders,
                checkIfEqual
              )
            },
            error => {
              this.logger.log(error)
              this.vehicleRemindersService.loading.vehicleReminders.next(false)
              this.vehicleRemindersService.vehicleRemindersLoaded(null)
              void this.localStorage.setReminders(vin, null, checkIfEqual)
            }
          )
      } else {
        this.vehicleRemindersService.loading.vehicleReminders.next(false)
      }

      void this.getDatafromLocalStorage(vehicle, preventLoading)
    }
  }

  getVehicleDocuments (vin, checkIfEqual?): void {
    this.vehicleDocumentsLoaded(null)
    this.vehicleDocumentsService.unreadVehicleDocuments(0)
    this.loadVehicleDataSub.getDocuments = this.vehicleDocumentsService
      .getDocuments(vin)
      .subscribe(
        documents => {
          this.vehicleDocumentsLoaded(documents)
          this.loading.documents.next(false)
          void this.localStorage.setVehicleDocuments(
            vin,
            documents,
            checkIfEqual
          )
        },
        error => {
          this.logger.log(error)
          this.vehicleDocumentsLoaded(null)
          this.loading.documents.next(false)
          void this.localStorage.setVehicleDocuments(vin, null, checkIfEqual)
        }
      )
  }

  warrantySPLoading (loading: boolean): void {
    if (this.remoteConfigService.get('features.servicePackage')) {
      this.loading.servicePackage.next(loading)
    }
    if (this.remoteConfigService.get('features.warranty')) {
      this.loading.warranty.next(loading)
    }
  }

  warrantySPLoaded (
    servicePackage: ServicePackageDTO[] | null,
    warranty: Warranty | null
  ): void {
    if (this.remoteConfigService.get('features.servicePackage')) {
      this.vehicleServicePackageLoaded(servicePackage)
    }
    if (this.remoteConfigService.get('features.warranty')) {
      this.vehicleWarrantyLoaded(warranty)
    }
  }

  unsubPreviousloadVehicleData (): void {
    for (const sub in this.loadVehicleDataSub) {
      try {
        this.loadVehicleDataSub[sub].unsubscribe()
      } catch (error) {
        // no need to show log
      }
    }
  }

  async getDatafromLocalStorage (
    vehicle: VehicleDTOExtended,
    preventLoading?: boolean
  ): Promise<void> {
    if (vehicle != null) {
      let vehicleData
      try {
        vehicleData = (await this.localStorage.getVehicleByVin(
          vehicle.vin
        )) as VehicleDTOExtended
      } catch (error) {
        this.logger.debug(error)
      }

      let invoices
      try {
        invoices = (await this.localStorage.getInvoices())
      } catch (error) {
        this.logger.debug(error)
      }

      // if the vehicle doesnt have anything in relations - the data was not previously loaded
      if (
        vehicleData != null &&
        Object.keys(vehicleData.relations).length > 0
      ) {
        if (preventLoading !== true) {
          this.setLoader(true)
        }

        // Recalls
        if (this.remoteConfigService.get('features.recalls')) {
          this.vehicleRecallsLoaded(
            vehicleData.relations.recalls != null
              ? vehicleData.relations.recalls
              : null
          )
        }

        // Vehicle tires
        this.vehicleTiresLoaded(
          vehicleData.relations.tires != null
            ? vehicleData.relations.tires
            : null
        )

        // Vehicle tire appointments
        if (this.remoteConfigService.get('hasServiceManager') === true) {
          this.vehicleTiresAppintmentLinkLoaded(
            vehicleData.relations.tireAppointmentLink != null
              ? vehicleData.relations.tireAppointmentLink
              : null
          )
        }

        // Vehicle documents
        this.vehicleDocumentsLoaded(
          vehicleData.relations.documents != null
            ? vehicleData.relations.documents
            : null
        )

        // invoices loaded
        this.invoicesLoaded(
          invoices
        )

        // Gallery
        this.galleriesLoaded(
          vehicleData.relations.galleries != null
            ? vehicleData.relations.galleries
            : null
        )

        // Warranty
        if (this.remoteConfigService.get('features.warranty')) {
          this.vehicleWarrantyLoaded(
            vehicleData.relations.warranty != null
              ? vehicleData.relations.warranty
              : null
          )
        }

        // Service Packages
        if (this.remoteConfigService.get('features.servicePackage')) {
          this.vehicleServicePackageLoaded(
            vehicleData.relations.servicePackage != null
              ? vehicleData.relations.servicePackage
              : null
          )
        }

        // Insurance
        if (this.remoteConfigService.get('hasInsurance') === true) {
          this.insuranceService.insuranceLoaded(
            vehicleData.relations.insurance != null
              ? vehicleData.relations.insurance
              : null
          )
        }

        // Vehicle promotions
        if (this.hasPromotions) {
          this.vehiclePromotionsLoaded(
            vehicleData.relations.promotions != null
              ? vehicleData.relations.promotions
              : null
          )
        }

        // Dealers
        this.vehicleDealersLoaded(
          vehicleData.relations.dealers != null
            ? vehicleData.relations.dealers
            : null
        )

        // Vehicle details
        this.vehicleDetailsLoaded(
          vehicleData.relations.vehicleDetails != null
            ? vehicleData.relations.vehicleDetails
            : null
        )

        // Frequent driver
        this.vehicleFrequentDriverLoaded(
          vehicleData.relations.frequentDriver != null
            ? vehicleData.relations.frequentDriver
            : null
        )

        // Reminders
        this.vehicleRemindersService.vehicleRemindersLoaded(
          vehicleData.relations.reminders != null
            ? vehicleData.relations.reminders
            : null
        )

        // timeout so the loader is at least visible for a second
        setTimeout(() => {
          this.setLoader(false)
        }, 200)
      }
    }
  }

  setLoader (loading: boolean): void {
    this.loading.promotions.next(loading)
    this.loading.documents.next(loading)
    this.loading.galleries.next(loading)
    this.loading.tires.next(loading)
    this.loading.recalls.next(loading)
    this.loading.serviceHistory.next(loading)
    this.loading.dealers.next(loading)
    this.loading.vehicleDetails.next(loading)
    this.loading.frequentDriver.next(loading)
    this.vehicleRemindersService.loading.vehicleReminders.next(loading)

    if (this.remoteConfigService.get('hasInsurance') === true) {
      this.insuranceService.loadingInsurance.next(loading)
    }
    if (this.remoteConfigService.get('features.servicePackage')) {
      this.loading.servicePackage.next(loading)
    }
    if (this.remoteConfigService.get('features.warranty')) {
      this.loading.warranty.next(loading)
    }
    if (this.remoteConfigService.get('hasServiceManager') === true) {
      this.loading.tireAppointments.next(loading)
    }
  }

  showLoader (): void {
    this.setLoader(true)
    setTimeout(() => {
      this.setLoader(false)
    }, 200)
  }

  getUser (): void {
    this.userService
      .getUser()
      .pipe(
        map(data => {
          if (data?.address?.[0] != null) {
            data.address = data.address.filter(address => {
              if (address.type === 'home') {
                return true
              }
              return !address.type
            })
          }
          return data
        })
      )
      .subscribe(user => {
        this.userLoaded(user)
      })
  }

  getConsents (partnerId?: number): void {
    this.loading.consents.next(true)
    this.userService.getConsents(partnerId).subscribe(
      consents => {
        this.userConsentsLoaded(consents)
        this.loading.consents.next(false)
      },
      error => {
        this.logger.log(error)
        this.loading.consents.next(false)
      }
    )
  }

  getConsentDealers (): void {
    this.loading.consentDealers.next(true)
    this.userService.getConsentDealers().subscribe(
      consentDealers => {
        this.consentDealersLoaded(consentDealers)
        this.loading.consentDealers.next(false)
      },
      error => {
        this.logger.log(error)
        this.loading.consentDealers.next(false)
      }
    )
  }

  // INVOICES
  async getInvoices (): Promise<void> {
    // get data from LS
    let LSInvoices: BasicDocumentDTOExtended[]
    try {
      LSInvoices = await this.localStorage.getInvoices()
    } catch (error) {
      this.logger.debug(error)
    }
    if (LSInvoices != null) {
      this.invoicesLoaded(LSInvoices)
      this.loading.invoices.next(false)
    } else {
      this.loading.invoices.next(true)
    }

    this.vehicleDocumentsService.getInvoices().subscribe({
      next: invoices => {
        this.invoicesLoaded(invoices)
        this.loading.invoices.next(false)
        void this.localStorage.setInvoices(invoices != null ? invoices : [], true)
      },
      error: error => {
        this.logger.debug(error)
        this.invoicesLoaded(null)
        this.loading.invoices.next(false)
        void this.localStorage.setInvoices(null, true)
      }
    })
  }

  // my documents
  async getMyDocuments (): Promise<void> {
  // get data from LS
    let LSMyDocuments: BasicDocumentDTOExtended[]
    try {
      LSMyDocuments = await this.localStorage.getMyDocuments()
    } catch (error) {
      this.logger.debug(error)
    }
    if (LSMyDocuments != null) {
      this.myDocumentsLoaded(LSMyDocuments)
      this.loading.myDocuments.next(false)
    } else {
      this.loading.myDocuments.next(true)
    }

    this.vehicleDocumentsService.getDocuments('', true).subscribe(
      documents => {
        const data = documents
          ?.filter(document => this.vehicleDocumentsService.filterOnlyDocumentSales(document))
          .sort((a, b) => Number(b?.active) - Number(a?.active))

        this.myDocumentsLoaded(data)
        this.loading.myDocuments.next(false)
        void this.localStorage.setMyDocuments(data != null ? data : [], true)
      },
      error => {
        this.logger.debug(error)
        this.myDocumentsLoaded(null)
        this.loading.myDocuments.next(false)
        void this.localStorage.setMyDocuments(null, true)
      }
    )
  }
}
