import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  EcondaService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { type TrackAndTraceVehicleDTOExtended, type VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-navbar-second',
  templateUrl: './navbar-second.component.html'
})
export class NavbarSecondComponent implements OnInit, OnDestroy {
  firstVehicleVin = ''
  homepage: string
  vehicles: VehicleDTOExtended[]
  inactiveVehicles: VehicleDTOExtended[]
  vehiclesTNT: TrackAndTraceVehicleDTOExtended[]
  // channels: boolean
  country
  unread = 0
  hasTestView
  hasTracking
  navigationMarketplace
  sub = {
    combinedVehiclesSubscription: null
  }

  constructor (
    public dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public router: Router,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
    this.country = this.remoteConfigService.get('country.code')
    this.hasTestView = this.remoteConfigService.get('hasTestView')
    this.navigationMarketplace = this.remoteConfigService.getArrayValues<any>(
      'navigationMarketplace'
    )
  }

  get hasVehicles (): boolean {
    return this.vehicles?.length > 0 || this.inactiveVehicles?.length > 0 || this.vehiclesTNT?.length > 0
  }

  get firstVehicle (): VehicleDTOExtended | TrackAndTraceVehicleDTOExtended {
    return this.vehicles?.[0] || this.vehiclesTNT?.[0] || this.inactiveVehicles?.[0]
  }

  ngOnInit (): void {
    this.sub.combinedVehiclesSubscription = this.dataService.combinedVehiclesLoaded
      .subscribe(([vehicles, inactiveVehicles, vehiclesTNT]) => {
        this.vehicles = vehicles
        this.inactiveVehicles = inactiveVehicles
        this.vehiclesTNT = vehiclesTNT?.filter(el => !(el.isHistory ?? false))
        if (this.hasVehicles) {
          this.firstVehicleVin = 'commissionNumber' in this.firstVehicle
            ? `commissionNumber-${this.firstVehicle.commissionNumber}`
            : this.firstVehicle?.vin
        }
      })
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Sub Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
    this.econdaService.send({
      content: 'Navigation',
      Target: [
        'Sub Navigation',
        this.sharedService.translateLink(label),
        1,
        'd'
      ]
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
